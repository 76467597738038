import {NotificationPreferencesConfig, UpdateNotificationPreference} from "../../Types.ts";
import jsonApi from "../jsonApi.ts";
import {invalidateQueries, q} from "../../state";

export async function get_(): Promise<NotificationPreferencesConfig> {
  return await jsonApi.get<NotificationPreferencesConfig>(`/notification_preferences`);
}

export async function update(preferenceConfig: UpdateNotificationPreference) {
  const res = await jsonApi.post<"ok">(`/notification_preferences/update`, preferenceConfig);
  await invalidateQueries([q.notificationPreferences.filter()]);
  return res;
}
