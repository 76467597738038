import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import {analyticsInit} from "./utils/analytics";
import {initRouter} from "./router/init";
import App from "./App";

analyticsInit();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

initRouter().then(() => {
  root.render(<App />);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
