import api from "../../api";
import {UserId} from "../../Types";
import {param, typedQuery} from "../typing";

export const user = typedQuery(["user", param<UserId>("userId")], async userId => {
  return await api.users.get(userId);
});

export const registeredUsers = typedQuery(["registeredUsers"], async () => {
  return await api.users.listRegistered();
});

export const userTeams = typedQuery(["userTeams", param<UserId>("userId")], async userId => {
  return await api.users.getTeams(userId);
});
