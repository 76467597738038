import {ExternalAuthorizationId, CrmConnectionId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const crmConnectors = typedQuery(["vendorToolkit", "crmConnectors"], async () => {
  return await api.vendorToolkit.crmConnections.listConnectors();
});

export const crmConnector = typedQuery(
  ["vendorToolkit", "crmConnector", param("crmConnectorId")],
  async crmConnectorId => {
    return await api.vendorToolkit.crmConnections.getConnector(crmConnectorId);
  },
);

export const crmConnections = typedQuery(["vendorToolkit", "crmConnections"], async () => {
  return await api.vendorToolkit.crmConnections.list();
});

export const crmConnection = typedQuery(
  ["vendorToolkit", "crmConnection", param<CrmConnectionId>("crmConnectionId")],
  async crmConnectionId => {
    return await api.vendorToolkit.crmConnections.get(crmConnectionId);
  },
);

export const crmAccountInfo = typedQuery(
  [
    "vendorToolkit",
    "crmAccountInfo",
    param("crmConnectorId"),
    param<ExternalAuthorizationId>("externalAuthorizationId"),
  ],
  async (crmConnectorId, externalAuthorizationId) => {
    return await api.vendorToolkit.crmConnections.getAccountInfo(crmConnectorId, externalAuthorizationId);
  },
);
