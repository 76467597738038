import {CreateLoginMethod, LoginMethod, RequestLoginReset, ResetLoginInfo} from "../Types";
import {invalidateQueries, q} from "../state";
import jsonApi, {DEFAULT_API_OPTIONS} from "./jsonApi";

export async function get() {
  return await jsonApi.get<LoginMethod>("/login/method");
}

export async function set(loginMethod: LoginMethod) {
  const res = await jsonApi.post<"ok">("/login/method", loginMethod);
  await invalidateQueries([q.registeredUsers.filter(), q.user.filter(), q.whoAmI.filter(), q.loginMethod.filter()]);
  return res;
}

export async function validateResetToken(token: string) {
  return await jsonApi.get<ResetLoginInfo>("/login/reset", {
    ...DEFAULT_API_OPTIONS,
    handleAuthErrors: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function reset(token: string, loginMethod: LoginMethod, payload: CreateLoginMethod) {
  return await jsonApi.post<"ok">(`/login/reset/${loginMethod}`, payload, {
    ...DEFAULT_API_OPTIONS,
    handleAuthErrors: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function requestReset(requestLoginReset: RequestLoginReset) {
  return await jsonApi.post<"ok">("/login/request_reset", requestLoginReset);
}

export async function upsert(loginMethod: LoginMethod, payload: CreateLoginMethod) {
  const res = await jsonApi.put<"ok">(`/login/methods/${loginMethod}`, payload);
  await invalidateQueries([q.loginMethod.filter()]);
  return res;
}

export async function delete_(loginMethod: LoginMethod) {
  const res = await jsonApi.delete_<"ok">(`/login/methods/${loginMethod}`);
  await invalidateQueries([q.loginMethod.filter()]);
  return res;
}

export async function list() {
  return await jsonApi.get<LoginMethod[]>(`/login/methods`);
}
