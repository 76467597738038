import {FrameworkId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const framework = typedQuery(["tprm", "framework", param<FrameworkId>("frameworkId")], async frameworkId => {
  return await api.tprm.frameworks.get(frameworkId);
});

export const frameworkVersions = typedQuery(
  ["tprm", "frameworkVersions", param<FrameworkId>("frameworkId")],
  async frameworkId => {
    return await api.tprm.frameworks.listVersions(frameworkId);
  },
);

export const frameworks = typedQuery(["tprm", "frameworks"], async () => {
  return await api.tprm.frameworks.list();
});
