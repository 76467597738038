import {BackgroundTaskId} from "../../Types";
import api, {HTTPError} from "../../api";
import {param, typedQuery} from "../typing";

export const backgroundTask = typedQuery(
  ["backgroundTask", param<BackgroundTaskId>("backgroundTaskId")],
  async backgroundTaskId => {
    try {
      return await api.backgroundTasks.get(backgroundTaskId);
    } catch (ex) {
      if (ex instanceof HTTPError && ex.response.status === 404) {
        // Task is complete
        return null;
      }
      throw ex;
    }
  },
);
