import {useToast} from "@chakra-ui/react";
import {useEffect} from "react";
import {TOAST_TRIGGER_STORAGE_KEY} from ".";

// If we try to show the toast immediately it doesn't work for some reason...
const TOAST_DELAY = 3000;

export function AppUpdatedMessage() {
  const toast = useToast();
  useEffect(() => {
    if (sessionStorage.getItem(TOAST_TRIGGER_STORAGE_KEY)) {
      sessionStorage.removeItem(TOAST_TRIGGER_STORAGE_KEY);
      setTimeout(() => {
        toast({description: "The application was reloaded because it was out of date.", isClosable: true});
      }, TOAST_DELAY);
    }
  }, [toast]);
  return null;
}
