import {Control, ControlId} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function load(controlIds: ControlId[]) {
  return await jsonApi.post<(Control | null)[]>(`/tprm/controls/load`, controlIds);
}

export async function updateTitle(controlId: ControlId, title: string) {
  const res = await jsonApi.post<"ok">(`/tprm/controls/${controlId}/title`, title);
  await invalidateQueries([q.tprm.control.filter(controlId)]);
  return res;
}

export async function updateDescription(controlId: ControlId, description: string) {
  const res = await jsonApi.post<"ok">(`/tprm/controls/${controlId}/description`, description);
  await invalidateQueries([q.tprm.control.filter(controlId)]);
  return res;
}

export async function reorder(controlId: ControlId, ordinal: number) {
  const res = await jsonApi.post<"ok">(`/tprm/controls/${controlId}/ordinal`, ordinal);
  await invalidateQueries([q.tprm.frameworkProfile.filter()]);
  return res;
}
