import {ReactNode, useContext, useEffect, useMemo, useState, createContext} from "react";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import {Global} from "@emotion/react";

type ThemeContextType = {
  addExtensions?: (extensions: any[]) => () => void;
};

const ThemeContext = createContext<ThemeContextType>({});

export default function ExtendTheme({children, extension}: {children?: ReactNode; extension: any}) {
  const {addExtensions} = useContext(ThemeContext);
  const [nestedExtensions, setNestedExtensions] = useState<any[][]>([]);

  const value = useMemo(
    () => ({
      addExtensions(newExtensions: any[]) {
        setNestedExtensions(prev => [...prev, newExtensions]);
        return () => setNestedExtensions(prev => prev.filter(x => x !== newExtensions));
      },
    }),
    [],
  );

  useEffect(
    () => addExtensions && addExtensions([extension, ...nestedExtensions.flat()]),
    [extension, nestedExtensions, addExtensions],
  );

  const rootTheme = useMemo(
    () => (addExtensions ? null : extendTheme(extension, ...nestedExtensions.flat())),
    [addExtensions, extension, nestedExtensions],
  );

  return (
    <ThemeContext.Provider value={value}>
      {rootTheme ? (
        <ChakraProvider theme={rootTheme}>
          <Global styles={rootTheme?.globalCss} />
          {children}
        </ChakraProvider>
      ) : (
        children
      )}
    </ThemeContext.Provider>
  );
}
