import {
  CreateNotificationChannel,
  NotificationChannel,
  NotificationChannelMin,
  NotificationChannelConnector,
  NotificationChannelConnectorType,
  LinkNotificationChannel,
  LinkedNotificationChannelMin,
  NotificationChannelConfig,
  UpdateNotificationChannel,
  ExternalAuthorizationId,
  NotificationChannelId,
} from "../../Types.ts";
import jsonApi from "../jsonApi.ts";
import {invalidateQueries, q} from "../../state";

export async function listConnectors(): Promise<NotificationChannelConnector[]> {
  return await jsonApi.get<NotificationChannelConnector[]>("/notification_channel_connectors");
}

export async function getConnector(
  connectorType: NotificationChannelConnectorType,
): Promise<NotificationChannelConnector> {
  return await jsonApi.get<NotificationChannelConnector>(`/notification_channel_connectors/${connectorType}`);
}

export async function getDefaultConfig(
  connectorType: NotificationChannelConnectorType,
  externalAuthorizationId: ExternalAuthorizationId,
): Promise<NotificationChannelConfig> {
  return await jsonApi.post<NotificationChannelConfig>(
    `/notification_channel_connectors/${connectorType}/default_config`,
    externalAuthorizationId,
  );
}

export async function create(payload: CreateNotificationChannel): Promise<NotificationChannel> {
  const res = await jsonApi.post<NotificationChannel>("/notification_channels", payload);
  await invalidateQueries([q.notificationChannels.filter()]);
  return res;
}

export async function update(channelId: NotificationChannelId, payload: UpdateNotificationChannel) {
  const res = await jsonApi.post<"ok">(`/notification_channels/${channelId}`, payload);
  await invalidateQueries([q.notificationChannels.filter(), q.notificationChannel.filter(channelId)]);
  return res;
}

export async function delete_(channelId: NotificationChannelId) {
  const res = await jsonApi.delete_<"ok">(`/notification_channels/${channelId}`);
  await invalidateQueries([q.notificationChannels.filter(), q.notificationChannel.filter(channelId)]);
  return res;
}

export async function updateName(channelId: NotificationChannelId, name: string) {
  const res = await jsonApi.post<"ok">(`/notification_channels/${channelId}/name`, name);
  await invalidateQueries([q.notificationChannels.filter(), q.notificationChannel.filter(channelId)]);
  return res;
}

export async function list(): Promise<NotificationChannelMin[]> {
  return await jsonApi.get<NotificationChannelMin[]>("/notification_channels");
}

export async function get(channelId: NotificationChannelId): Promise<NotificationChannel> {
  return await jsonApi.get<NotificationChannel>(`/notification_channels/${channelId}`);
}

export async function listAvailable(): Promise<NotificationChannelMin[]> {
  return await jsonApi.get<NotificationChannelMin[]>("/available_notification_channels");
}

export async function linkChannel(link: LinkNotificationChannel): Promise<LinkedNotificationChannelMin> {
  const res = await jsonApi.post<LinkedNotificationChannelMin>("/user_notification_channels", link);
  await invalidateQueries([q.availableNotificationChannels.filter(), q.linkedNotificationChannels.filter()]);
  return res;
}

export async function listLinked(): Promise<LinkedNotificationChannelMin[]> {
  return await jsonApi.get<LinkedNotificationChannelMin[]>("/user_notification_channels");
}

export async function deleteLinked(channelId: NotificationChannelId) {
  const res = await jsonApi.delete_<"ok">(`/user_notification_channels/${channelId}`);
  await invalidateQueries([q.availableNotificationChannels.filter(), q.linkedNotificationChannels.filter()]);
  return res;
}
