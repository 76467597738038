import {param, typedQuery} from "../../typing.ts";
import api from "../../../api";
import {CoreResponseId} from "../../../Types.ts";

export const coreResponses = typedQuery(["vendorToolkit", "coreResponses"], async () => {
  return await api.vendorToolkit.coreResponses.list();
});

export const coreResponse = typedQuery(
  ["vendorToolkit", "coreResponse", param<CoreResponseId>("coreResponseId")],
  async coreResponseId => {
    return await api.vendorToolkit.coreResponses.get(coreResponseId);
  },
);
