import {
  DefaultError,
  InvalidateOptions,
  InvalidateQueryFilters,
  MutationKey,
  MutationOptions,
  QueryClient,
  QueryKey,
  UseSuspenseQueryOptions,
  useIsMutating,
} from "@tanstack/react-query";

export * as q from "./queries";
import {RedirectError} from "../api/jsonApi";
import {HTTPError} from "../api";
import {deferInvalidation, directlyInvalidateQuery} from "./invalidate";
import {DIAGNOSTICS, useSuspenseQueriesWithDiagnostics, useSuspenseQueryWithDiagnostics} from "../utils/diagnostics";
import {Nominal} from "../Types";
import {useRef} from "react";

function shouldRetry(failureCount: number, error: any) {
  if (failureCount >= 3) {
    return false;
  } else if (error instanceof RedirectError) {
    return false;
  } else if (error instanceof HTTPError && error.response.status < 500) {
    return false;
  } else {
    return true;
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: shouldRetry,
      staleTime: 5000,
    },
  },
});

type QueryData<T> = T extends {queryFn: (...args: any[]) => Promise<infer D>} ? D : never;

export function useQueryData<const T, TData = QueryData<T>>(options: T): TData {
  try {
    return useSuspenseQueryWithDiagnostics(options as any).data as TData;
  } catch (ex) {
    DIAGNOSTICS.reportPossibleSuspense(ex);
    throw ex;
  }
}

type CombinedQueriesData<T> = T extends ReadonlyArray<any> ? {[K in keyof T]: QueryData<T[K]>} : never;

export function useQueriesData<const T extends ReadonlyArray<any>, TCombinedResult = CombinedQueriesData<T>>({
  queries,
  preservePreviousData,
}: {
  queries: T;
  preservePreviousData?: boolean;
}): TCombinedResult {
  const prevResult = useRef(undefined);
  try {
    const res = useSuspenseQueriesWithDiagnostics({
      queries: queries.map(options => ({...options})) as unknown[],
    }).map(item => item.data) as any;
    if (preservePreviousData) {
      prevResult.current = res;
    }
    return res;
  } catch (ex) {
    if (ex instanceof Promise && prevResult.current !== undefined) {
      return prevResult.current;
    }
    throw ex;
  }
}

export type PlatformedQueryFilters = Nominal<InvalidateQueryFilters, "platformedQueryFilters">;

// Marks active queries matching the filter to be re-fetched, but leaves the existing data in the cache until
// the re-fetch has completed.
export async function invalidateQueries(filters?: PlatformedQueryFilters[]) {
  await deferInvalidation(() =>
    Promise.all((filters ?? [undefined]).map(filter => directlyInvalidateQuery(filter as PlatformedQueryFilters))),
  );
}

// Resets all the queries matching the filter to their initial state, clearing matching cache keys.
export async function resetQueries(filters?: PlatformedQueryFilters[], options?: InvalidateOptions) {
  await Promise.all(
    (filters ?? [undefined]).map(filter => queryClient.resetQueries(filter as InvalidateQueryFilters, options)),
  );
}

// Directly store data in the cache under the given key.
export async function setQueryData<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseSuspenseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  updater: TData | undefined | ((oldData: TData | undefined) => TData | undefined),
) {
  queryClient.setQueryData<TData>(options.queryKey, updater);
}

export async function mutate<TData = unknown, TError = DefaultError, TContext = unknown>(
  options: MutationOptions<TData, TError, void, TContext>,
) {
  queryClient.getMutationCache().build(queryClient, options).execute(undefined);
}

export function useMutationDone({mutationKey}: {mutationKey: MutationKey}) {
  const isMutationInProgress = useIsMutating({mutationKey});
  if (isMutationInProgress) {
    const cache = queryClient.getMutationCache();
    throw new Promise(resolve => {
      const unsubscribe = cache.subscribe(() => {
        if (cache.findAll({mutationKey}).every(mutation => mutation.state.status !== "pending")) {
          unsubscribe();
          resolve(null);
        }
      });
    });
  }
}
