import { Json } from "./protocol"

export type Condition = { type: "Literal"; content: boolean }

export interface AnswerPart<T> {
  enabled: Condition
  config: T
}

export interface YesNoAnswerConfig {}

export interface AnswerOption {
  value: string
  label: string
  allow_other_text: boolean
}

export interface SelectAnswerConfig {
  options: AnswerOption[]
  max_selected?: number
}

export interface TextAnswerConfig {
  word_count_limit?: number
}

export interface FilesAnswerConfig {
  min_number?: number
  max_numer?: number
}

export interface AnswerParts {
  yes_no: AnswerPart<YesNoAnswerConfig>
  select: AnswerPart<SelectAnswerConfig>
  text: AnswerPart<TextAnswerConfig>
  files: AnswerPart<FilesAnswerConfig>
}

export type ImportedQuestion = {
  question_number?: string
  text: string
  parts?: AnswerParts
  guidance?: string
  sectionTitle?: string
  overrideExisting?: boolean
}

export type Fingerprint = {
  providerType: string
  identifier?: string
  hostname: string
  url: string
}

export type PlatformedEvent =
  | {
      type: "Fingerprinted"
      fingerprint: Fingerprint | null
    }
  | {
      type: "LoginStateChanged"
    }

export enum LogType {
  Debug = "debug",
  Info = "info",
  Trace = "trace",
  Warn = "warn",
  Error = "error",
  Dir = "dir",
  Log = "log",
}

export type PlatformedApi = {
  requests: {
    getQuestions: () => ImportedQuestion[]
    getPageSnapshotAsBase64: () => string
    logMessage: (logType: LogType, args: readonly Json[]) => void
    openApp: () => void
  }
  event: PlatformedEvent
}

export const CURRENT_VERSION = 1
export const CHANNEL_KEY = "__platformed"
