import api from "../../../api";
import {CountResolutionsArgs, FactClusterId, FactSearchArgs, ListResolutionsArgs, ResolutionId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const facts = typedQuery(["vendorToolkit", "facts", param<FactSearchArgs>()], async factSearchArgs => {
  return await api.vendorToolkit.facts.search(factSearchArgs);
});

export const topLevelFactClusters = typedQuery(["vendorToolkit", "topLevelFactClusters"], async () => {
  return await api.vendorToolkit.facts.listTopLevelClusters();
});

export const factEquivalences = typedQuery(
  ["vendorToolkit", "factEquivalences", param<FactClusterId>("clusterId")],
  async clusterId => {
    return await api.vendorToolkit.facts.listEquivalences(clusterId);
  },
);

export const factsInCluster = typedQuery(
  ["vendorToolkit", "factsInCluster", param<FactClusterId>("clusterId")],
  async clusterId => {
    return await api.vendorToolkit.facts.listFactsInCluster(clusterId);
  },
);

export const resolutions = typedQuery(
  ["vendorToolkit", "resolutions", param<ListResolutionsArgs>("args")],
  async args => {
    return await api.vendorToolkit.facts.listResolutions(args);
  },
);

export const resolutionsCount = typedQuery(
  ["vendorToolkit", "resolutionsCount", param<CountResolutionsArgs>("args")],
  async args => {
    return await api.vendorToolkit.facts.countResolutions(args);
  },
);

export const resolvedResolutions = typedQuery(["vendorToolkit", "resolvedResolutions"], async () => {
  return await api.vendorToolkit.facts.listResolvedResolutions();
});

export const ignoredResolutions = typedQuery(["vendorToolkit", "ignoredResolutions"], async () => {
  return await api.vendorToolkit.facts.listIgnoredResolutions();
});

export const resolution = typedQuery(
  ["vendorToolkit", "resolution", param<ResolutionId>("resolutionId")],
  async resolutionId => {
    return await api.vendorToolkit.facts.getResolution(resolutionId);
  },
);
