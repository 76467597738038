import {
  QuestionSourceConnector,
  QuestionSource,
  CreateQuestionSource,
  QuestionSourceMin,
  QuestionSourceAccountInfo,
  UpdateQuestionSource,
  ExternalAuthorizationId,
  QuestionSourceId,
} from "../../Types";
import jsonApi from "../jsonApi";
import {invalidateQueries, q} from "../../state";

export async function listConnectors(): Promise<QuestionSourceConnector[]> {
  return await jsonApi.get<QuestionSourceConnector[]>("/vendor_toolkit/question_source_connectors");
}

export async function getConnector(connectorId: string): Promise<QuestionSourceConnector> {
  return await jsonApi.get<QuestionSourceConnector>(`/vendor_toolkit/question_source_connectors/${connectorId}`);
}

export async function getAccountInfo(
  connectorId: string,
  externalAuthorizationId: ExternalAuthorizationId,
): Promise<QuestionSourceAccountInfo> {
  return await jsonApi.post<QuestionSourceAccountInfo>(
    `/vendor_toolkit/question_source_connectors/${connectorId}/account_info`,
    externalAuthorizationId,
  );
}

export async function list(): Promise<QuestionSourceMin[]> {
  return await jsonApi.get<QuestionSource[]>("/vendor_toolkit/question_sources");
}

export async function get(connectionId: QuestionSourceId): Promise<QuestionSource> {
  return await jsonApi.get<QuestionSource>(`/vendor_toolkit/question_sources/${connectionId}`);
}

export async function create(connection: CreateQuestionSource): Promise<QuestionSource> {
  const res = await jsonApi.post<QuestionSource>(`/vendor_toolkit/question_sources`, connection);
  await invalidateQueries([q.vendorToolkit.questionSources.filter()]);
  return res;
}

export async function updateName(connectionId: QuestionSourceId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/question_sources/${connectionId}/name`, name);
  await invalidateQueries([
    q.vendorToolkit.questionSource.filter(connectionId),
    q.vendorToolkit.questionSources.filter(),
  ]);
  return res;
}
export async function update(connectionId: QuestionSourceId, update: UpdateQuestionSource) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/question_sources/${connectionId}`, update);
  await invalidateQueries([
    q.vendorToolkit.questionSource.filter(connectionId),
    q.vendorToolkit.questionSources.filter(),
  ]);
  return res;
}
