import {Task, GettingStartedChecklist, PaginationArgs, Paginated, LayerType} from "../../Types";
import jsonApi from "../jsonApi";

export async function list(layerType: LayerType, paginationArgs: PaginationArgs) {
  return await jsonApi.get<Paginated<Task>>(
    `/vendor_toolkit/tasks/${layerType}?${jsonApi.encodeQuery(paginationArgs)}`,
  );
}

export async function count(layerType: LayerType) {
  return await jsonApi.get<number>(`/vendor_toolkit/tasks/${layerType}/count`);
}

export async function getGettingStartedChecklist() {
  return await jsonApi.get<GettingStartedChecklist>("/vendor_toolkit/getting_started_checklist");
}
