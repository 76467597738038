import {
  AnswerParts,
  ApiDateTime,
  BackgroundTask,
  BulkQuestionUpdate,
  CreateQuestionDocumentation,
  Documentation,
  DocumentationId,
  LayerType,
  OwnerId,
  PromptModifiers,
  Question,
  QuestionId,
  QuestionStatus,
  SourcedFact,
  ResponseOption,
  RelevantFact,
  CoreResponseId,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function get(questionId: QuestionId) {
  return await jsonApi.get<Question>(`/vendor_toolkit/questions/${questionId}`);
}

export async function load(questionIds: QuestionId[]) {
  return await jsonApi.post<(Question | null)[]>(`/vendor_toolkit/questions/load`, questionIds);
}

export async function assign(questionId: QuestionId, layerType: LayerType, ownerId: OwnerId | null) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/assign`,
    ownerId,
  );
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.watchingQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.tasks.filter(),
  ]);
  return res;
}

export async function updateStatus(questionId: QuestionId, layerType: LayerType, status: QuestionStatus) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/status`,
    status,
  );
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.tasks.filter(),
    q.vendorToolkit.questionnaire.filter(),
  ]);
  return res;
}

export async function updateDueDate(questionId: QuestionId, layerType: LayerType, dueDate: ApiDateTime | null) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/due_date`,
    dueDate,
  );
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.tasks.filter(),
  ]);
  return res;
}

export async function updateNumber(questionId: QuestionId, questionNumber: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questions/${questionId}/number`, questionNumber);
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.questionnaire.filter(),
  ]);
  return res;
}

export async function updateText(questionId: QuestionId, text: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questions/${questionId}/text`, text);
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.questionnaire.filter(),
  ]);
  return res;
}

export async function updateGuidance(questionId: QuestionId, guidance: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questions/${questionId}/guidance`, guidance);
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
  ]);
  return res;
}

export async function updateResponseText(questionId: QuestionId, layerType: LayerType, responseText: string | null) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/response_text`,
    responseText,
  );
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
  ]);
  return res;
}

export async function updateResponseYesNo(
  questionId: QuestionId,
  layerType: LayerType,
  responseYesNo: boolean | undefined,
) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/response_yes_no`,
    responseYesNo ?? null,
  );
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
  ]);
  return res;
}

export async function updateResponseSelect(
  questionId: QuestionId,
  layerType: LayerType,
  responseSelect: ResponseOption[] | undefined,
) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/response_select`,
    responseSelect ?? null,
  );
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
  ]);
  return res;
}

export async function updateLinkedCoreResponse(
  questionId: QuestionId,
  layerType: LayerType,
  coreResponse: CoreResponseId | null,
) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/core_response`,
    coreResponse,
  );

  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
  ]);
  return res;
}

export async function updateParts(questionId: QuestionId, parts: AnswerParts) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questions/${questionId}/parts`, parts);
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
  ]);
  return res;
}

export async function updatePromptModifiers(questionId: QuestionId, promptModifiers: PromptModifiers) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questions/${questionId}/prompt_modifiers`, promptModifiers);
  await invalidateQueries([
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
  ]);
  return res;
}

export const createDocumentation = async (
  questionId: QuestionId,
  layerType: LayerType,
  documentation: CreateQuestionDocumentation[],
) => {
  const res = await jsonApi.post<string[]>(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/documentation`,
    documentation,
  );
  await invalidateQueries([
    q.vendorToolkit.questionDocumentation.filter(questionId),
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.questionnaireDocumentation.filter(),
    q.vendorToolkit.questionnaire.filter(),
  ]);
  return res;
};

export const listDocumentation = async (questionId: QuestionId, layerType: LayerType) => {
  return await jsonApi.get<Documentation[]>(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/documentation`,
  );
};

export const deleteDocumentation = async (
  questionId: QuestionId,
  layerType: LayerType,
  documentationId: DocumentationId,
) => {
  const res = await jsonApi.delete_<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/documentation/${documentationId}`,
  );
  await invalidateQueries([
    q.vendorToolkit.questionDocumentation.filter(questionId),
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.questionnaireDocumentation.filter(),
    q.vendorToolkit.questionnaire.filter(),
  ]);
  return res;
};

export const deleteAllDocumentation = async (questionId: QuestionId, layerType: LayerType) => {
  const res = await jsonApi.delete_<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/documentation`,
  );
  await invalidateQueries([
    q.vendorToolkit.questionDocumentation.filter(questionId),
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.questionnaireDocumentation.filter(),
    q.vendorToolkit.questionnaire.filter(),
  ]);
  return res;
};

export async function bulkUpdate(update: BulkQuestionUpdate) {
  const res = await jsonApi.patch<"ok">(`/vendor_toolkit/questions`, update);
  await invalidateQueries([
    q.vendorToolkit.questionnaireDocumentation.filter(),
    q.vendorToolkit.question.filter(),
    q.vendorToolkit.facts.filter(),
    q.vendorToolkit.tasks.filter(),
    q.vendorToolkit.adhocQuestion.filter(),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.questionnaire.filter(),
  ]);
  return res;
}

export async function automaticallyAnswer(questionId: QuestionId) {
  const res = jsonApi.post<BackgroundTask>(`/vendor_toolkit/questions/${questionId}/auto_complete`);
  await invalidateQueries([q.vendorToolkit.question.filter(questionId)]);
  return res;
}

export async function listUsedFacts(questionId: QuestionId) {
  return await jsonApi.get<RelevantFact[]>(`/vendor_toolkit/questions/${questionId}/used_facts`);
}

export async function listGeneratedFacts(questionId: QuestionId) {
  return await jsonApi.get<SourcedFact[]>(`/vendor_toolkit/questions/${questionId}/generated_facts`);
}

export async function copyBack(questionId: QuestionId, layerType: LayerType) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/questions/${questionId}/response_layers/${layerType}/copy_back`,
  );
  await invalidateQueries([
    q.vendorToolkit.questionnaireDocumentation.filter(),
    q.vendorToolkit.questionnaire.filter(),
    q.vendorToolkit.adhocQuestions.filter(),
    q.vendorToolkit.question.filter(questionId),
    q.vendorToolkit.adhocQuestion.filter(questionId),
    q.vendorToolkit.questionDocumentation.filter(questionId),
  ]);
  return res;
}

export async function getWatching(questionId: QuestionId) {
  return await jsonApi.get<boolean>(`/vendor_toolkit/questions/${questionId}/watching`);
}

export async function setWatching(questionId: QuestionId, isWatching: boolean) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/questions/${questionId}/watching`, isWatching);
  await invalidateQueries([q.vendorToolkit.watchingQuestion.filter(questionId)]);
  return res;
}
