import api from "../../api";
import {param, typedQuery} from "../typing";
import {ResolveOwner, OwnerId} from "../../Types.ts";

export const resolvedOwner = typedQuery(["resolvedOwner", param<ResolveOwner>()], async resolveOwner => {
  const owners = await api.owners.resolve([resolveOwner]);
  return owners[0];
});

export const owner = typedQuery(["owner", param<OwnerId>("ownerId")], async ownerId => {
  return await api.owners.get(ownerId);
});
