import api from "../../api";
import {RoleId} from "../../Types";
import {param, typedQuery} from "../typing";

export const roles = typedQuery(["roles"], async () => {
  return await api.roles.list();
});

export const roleMembers = typedQuery(["roleMembers", param<RoleId>("roleId")], async roleId => {
  return await api.roles.listMembers(roleId);
});
