import {FrameworkProfileId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const frameworkProfile = typedQuery(
  ["tprm", "frameworkProfiles", param<FrameworkProfileId>("frameworkProfileId")],
  async frameworkProfileId => {
    return await api.tprm.frameworkProfiles.get(frameworkProfileId);
  },
);
