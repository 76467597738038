import {TprmQuestionnaireTemplateId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const questionnaireTemplates = typedQuery(["tprm", "questionnaireTemplates"], async () => {
  return await api.tprm.questionnaireTemplates.list();
});

export const questionnaireTemplate = typedQuery(
  ["tprm", "questionnaireTemplate", param<TprmQuestionnaireTemplateId>("questionnaireTemplateId")],
  async questionnaireTemplateId => {
    return await api.tprm.questionnaireTemplates.get(questionnaireTemplateId);
  },
);
