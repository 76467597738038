import {TeamId} from "../../Types";
import api from "../../api";
import {param, typedQuery} from "../typing";

export const team = typedQuery(["team", param<TeamId>("teamId")], async teamId => {
  return await api.teams.get(teamId);
});

export const teams = typedQuery(["teams"], async () => {
  return await api.teams.list();
});

export const deletedTeams = typedQuery(["deletedTeams"], async () => {
  return await api.teams.listDeleted();
});

export const teamMembers = typedQuery(["teamMembers", param<TeamId>("teamId")], async teamId => {
  return await api.teams.listMembers(teamId);
});

export const teamPermissions = typedQuery(["teamPermissions", param<TeamId>("teamId")], async teamId => {
  return await api.teams.listPermissions(teamId);
});
