import {TprmExternalQuestionnaireId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const externalQuestionnaire = typedQuery(
  ["tprm", "externalQuestionnaire", param<TprmExternalQuestionnaireId>("tprmExternalQuestionnaireId")],
  async externalQuestionnaireId => {
    return await api.tprm.externalQuestionnaires.get(externalQuestionnaireId);
  },
);
