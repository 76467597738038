import api from "../../api";
import {typedQuery} from "../typing";

export const loginMethod = typedQuery(["loginMethod"], async () => {
  return await api.loginMethod.get();
});

export const loginMethods = typedQuery(["loginMethods"], async () => {
  return await api.loginMethod.list();
});
