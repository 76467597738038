import { RpcError } from "."
import { IChannel } from "./channel"
import { IDisposable } from "./disposable"
import {
  JsonObject,
  RpcClientMessage,
  RpcClientRequestMessage,
  RpcServerMessage,
  RpcServerResponse,
  UNVERSIONED,
} from "./protocol"
import { ApiSpec } from "./spec"

export class RpcClient<ApiSpecT extends ApiSpec> implements IDisposable {
  #nextRequestId = 1
  #inFlightRequests = new Map<
    number,
    { resolve: (result: any) => void; reject: (error: any) => void }
  >()
  #isReady = false
  #supportedVersion = false
  #bufferedRequests: RpcClientRequestMessage[] = []
  #channel: IChannel
  #version: number
  #unsubscribe: () => void

  constructor(channel: IChannel, version?: number) {
    this.#channel = channel
    this.#version = version ?? UNVERSIONED
    this.#unsubscribe = this.#channel.subscribe(this.#onMessage)
    this.#postMessage({ type: "hello" })
  }

  dispose() {
    this.#unsubscribe()
  }

  onHello(_supportedVersion: boolean): void {}
  onEvent(_event: ApiSpecT["event"]): void {}

  #onMessage = (untypedMsg: JsonObject) => {
    const msg = untypedMsg as RpcServerMessage
    switch (msg.type) {
      case "hello":
        {
          this.#supportedVersion = msg.version === this.#version
          this.#isReady = true
          const bufferedRequests = this.#bufferedRequests
          this.#bufferedRequests = []
          for (const req of bufferedRequests) {
            this.#postRequest(req)
          }

          this.onHello(this.#supportedVersion)
        }
        break
      case "event":
        if (this.#supportedVersion) {
          this.onEvent(msg.payload)
        }
        break
      case "response":
        if (this.#supportedVersion) {
          this.#resolveRequest(msg.requestId, msg.response)
        }
        break
    }
  }

  #postMessage(msg: RpcClientMessage) {
    this.#channel.send(msg)
  }

  #resolveRequest(requestId: number, response: RpcServerResponse) {
    const req = this.#inFlightRequests.get(requestId)
    this.#inFlightRequests.delete(requestId)
    if (req) {
      if (response.success) {
        req.resolve(response.payload)
      } else {
        req.reject(new RpcError(response.error))
      }
    }
  }

  #postRequest(msg: RpcClientRequestMessage) {
    if (this.#isReady) {
      if (this.#supportedVersion) {
        this.#postMessage(msg)
      } else {
        this.#resolveRequest(msg.requestId, {
          success: false,
          error: "Unsupported extension version",
        })
      }
    } else {
      this.#bufferedRequests.push(msg)
    }
  }

  request<K extends keyof ApiSpecT["requests"] & string>(
    requestType: K,
    ...payload: Parameters<ApiSpecT["requests"][K]>
  ): Promise<ReturnType<ApiSpecT["requests"][K]>> {
    const requestId = this.#nextRequestId++
    return new Promise<ReturnType<ApiSpecT["requests"][K]>>(
      (resolve, reject) => {
        this.#inFlightRequests.set(requestId, { resolve, reject })
        this.#postRequest({
          type: "request",
          requestId,
          requestType,
          payload,
        })
      }
    )
  }
}
