import {HTTPError} from "..";
import {getFrontendEnvironment} from "../../utils/environment";
import {fetchWithRetry} from "../jsonApi";

// TODO: Once we can make typeshare output readonly types we should uncomment this
//export type Json = null | boolean | number | string | readonly Json[] | {readonly [key: string]: Json};
export type Json = unknown;
export type ApiOptions = {
  headers?: HeadersInit;
};
const DEFAULT_API_OPTIONS: ApiOptions = {};

const {app_url} = getFrontendEnvironment();
export const API_BASE = `${app_url}/external_api`;

export async function handleResponseError(response: Response) {
  if (!response.ok) {
    throw new HTTPError(response);
  }
}

async function get<T extends Json>(path: string, options = DEFAULT_API_OPTIONS): Promise<T> {
  const resp = await fetchWithRetry(`${API_BASE}${path}`, {
    headers: {
      Accept: "application/json",
      ...(options.headers ?? {}),
    },
    credentials: "include",
  });
  await handleResponseError(resp);

  return await resp.json();
}

function download(path: string) {
  const a = document.createElement("a");
  a.href = `${API_BASE}${path}`;
  a.click();
}

async function post<T extends Json>(path: string, body: Json = {}, options = DEFAULT_API_OPTIONS): Promise<T> {
  const resp = await fetchWithRetry(`${API_BASE}${path}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(options.headers ?? {}),
    },
    body: JSON.stringify(body),
    credentials: "include",
  });
  await handleResponseError(resp);

  return await resp.json();
}

async function put<T extends Json>(path: string, body: Json = {}, options = DEFAULT_API_OPTIONS): Promise<T> {
  const resp = await fetchWithRetry(`${API_BASE}${path}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(options.headers ?? {}),
    },
    body: JSON.stringify(body),
    credentials: "include",
  });
  await handleResponseError(resp);

  return await resp.json();
}

async function patch<T extends Json>(path: string, body: Json = {}, options = DEFAULT_API_OPTIONS): Promise<T> {
  const resp = await fetchWithRetry(`${API_BASE}${path}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(options.headers ?? {}),
    },
    body: JSON.stringify(body),
    credentials: "include",
  });
  await handleResponseError(resp);

  return await resp.json();
}

async function delete_<T extends Json>(path: string, options = DEFAULT_API_OPTIONS): Promise<T> {
  const resp = await fetchWithRetry(`${API_BASE}${path}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      ...(options.headers ?? {}),
    },
    credentials: "include",
  });
  await handleResponseError(resp);

  return await resp.json();
}

function encodeQuery(args: object): string {
  return new URLSearchParams(Object.entries(args).map(([k, v]) => [k, `${v}`])).toString();
}

const jsonApi = {get, download, post, put, patch, delete_, encodeQuery};

export default jsonApi;
