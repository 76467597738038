import {DataRoomId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const dataRoom = typedQuery(["tprm", "dataRoom", param<DataRoomId>("dataRoomId")], async dataRoomId => {
  return await api.tprm.dataRooms.get(dataRoomId);
});

export const dataRoomSources = typedQuery(
  ["tprm", "dataRoomSources", param<DataRoomId>("dataRoomId")],
  async dataRoomId => {
    return await api.tprm.dataRooms.listSources(dataRoomId);
  },
);
