import {
  AssessedControlIdPair,
  AssessedControl,
  RecordControlAssessment,
  AssessmentId,
  ControlId,
  ControlEvidenceId,
  ControlEvidence,
  RecordControlEvidence,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function load(controlIds: AssessedControlIdPair[]) {
  return await jsonApi.post<(AssessedControl | null)[]>(`/tprm/assessed_controls/load`, controlIds);
}

export async function add(assessmentId: AssessmentId, controlId: ControlId) {
  const res = await jsonApi.post<"ok">(`/tprm/assessments/${assessmentId}/controls`, controlId);
  await invalidateQueries([q.tprm.assessedControl.filter(assessmentId, controlId)]);
  return res;
}

export async function assess(assessmentId: AssessmentId, controlId: ControlId, record: RecordControlAssessment) {
  const res = await jsonApi.post<"ok">(`/tprm/assessments/${assessmentId}/controls/${controlId}/assess`, record);
  await invalidateQueries([q.tprm.assessedControl.filter(assessmentId, controlId)]);
  return res;
}

export async function loadEvidence(evidenceIds: ControlEvidenceId[]) {
  return await jsonApi.post<(ControlEvidence | null)[]>(`/tprm/control_evidence/load`, evidenceIds);
}

export async function addEvidence(assessmentId: AssessmentId, controlId: ControlId, evidence: RecordControlEvidence) {
  const res = await jsonApi.post<ControlEvidenceId>(
    `/tprm/assessments/${assessmentId}/controls/${controlId}/evidence`,
    evidence,
  );
  await invalidateQueries([q.tprm.assessedControl.filter(assessmentId, controlId)]);
  return res;
}

export async function removeEvidence(assessmentId: AssessmentId, controlId: ControlId, evidenceId: ControlEvidenceId) {
  const res = await jsonApi.delete_<"ok">(
    `/tprm/assessments/${assessmentId}/controls/${controlId}/evidence/${evidenceId}`,
  );
  await invalidateQueries([q.tprm.assessedControl.filter(assessmentId, controlId)]);
  return res;
}
