import {param, typedQuery} from "../../typing.ts";
import api from "../../../api";
import {NotificationChannelConnectorType, NotificationChannelId} from "../../../Types.ts";

export const notificationChannelConnectors = typedQuery(["notificationChannelConnectors"], async () => {
  return await api.notificationChannels.listConnectors();
});

export const notificationChannelConnector = typedQuery(
  ["notificationChannelConnector", param<NotificationChannelConnectorType>("notificationChannelConnectorType")],
  async notificationChannelConnectorType => {
    return await api.notificationChannels.getConnector(notificationChannelConnectorType);
  },
);

export const notificationChannels = typedQuery(["notificationChannels"], async () => {
  return await api.notificationChannels.list();
});

export const notificationChannel = typedQuery(
  ["notificationChannel", param<NotificationChannelId>("notificationChannelId")],
  async notificationChannelId => {
    return await api.notificationChannels.get(notificationChannelId);
  },
);

export const availableNotificationChannels = typedQuery(["availableNotificationChannels"], async () => {
  return await api.notificationChannels.listAvailable();
});

export const linkedNotificationChannels = typedQuery(["linkedNotificationChannels"], async () => {
  return await api.notificationChannels.listLinked();
});
