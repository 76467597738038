import api from "../../../api";
import {LayerType, PaginationArgs} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const taskCount = typedQuery(["vendorToolkit", "taskCount", param<LayerType>("layerType")], async layerType => {
  return await api.vendorToolkit.tasks.count(layerType);
});

export const tasks = typedQuery(
  ["vendorToolkit", "tasks", param<LayerType>("layerType"), param<PaginationArgs>("args")],
  async (layerType, args) => {
    return await api.vendorToolkit.tasks.list(layerType, args);
  },
);

export const gettingStartedChecklist = typedQuery(["vendorToolkit", "gettingStartedChecklist"], async () => {
  return await api.vendorToolkit.tasks.getGettingStartedChecklist();
});
