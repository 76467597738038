import {QueryFunctionContext, QueryKey, hashKey} from "@tanstack/react-query";
import {PlatformedQueryFilters} from ".";
import {nominate} from "../Types";

// Used as a placeholder for dynamic parts of the query key
export const ParamSymbol = Symbol("<param>");
const TypeBrand = Symbol("_type");

// Type of a placeholder for a specific type
export type Param<T> = typeof ParamSymbol & {[TypeBrand]: T};

// Constructs a placeholder of a specific type
// `name` parameter is for documentation purposes only.
export function param<T = string>(_name?: string): Param<T> {
  return ParamSymbol as Param<T>;
}

// Given an array type representing a query key pattern, replaces placeholder
// types with the concrete types for those placeholders.
// eg. `Param<string> => string`
type ConcreteQueryKey<TQueryKeyPattern> = {
  readonly [I in keyof TQueryKeyPattern]: TQueryKeyPattern[I] extends Param<infer K> ? K : TQueryKeyPattern[I];
};

// New

type ExtractKeyParams<TQueryKeyPattern extends readonly QueryKeyPatternElement[]> = TQueryKeyPattern extends readonly [
  infer Head,
  ...infer Tail extends readonly QueryKeyPatternElement[],
]
  ? Head extends Param<infer K>
    ? [K, ...ExtractKeyParams<Tail>]
    : ExtractKeyParams<Tail>
  : TQueryKeyPattern extends readonly []
  ? []
  : never;

type TQueryFn<TQueryKeyPattern extends readonly QueryKeyPatternElement[], TData> = (
  context: QueryFunctionContext<ConcreteQueryKey<TQueryKeyPattern>, never>,
) => Promise<TData>;

interface TypedQuery<
  // Query key pattern for this query
  TQueryKeyPattern extends readonly QueryKeyPatternElement[],
  // Result of the query
  TData,
  // Separate type only so that we can preserve parameter names via the labelled tuples feature
  TParams extends ExtractKeyParams<TQueryKeyPattern> = ExtractKeyParams<TQueryKeyPattern>,
> {
  readonly queryKeyPattern: TQueryKeyPattern;
  (...params: TParams): {
    queryKey: ConcreteQueryKey<TQueryKeyPattern>;
    queryFn: TQueryFn<TQueryKeyPattern, TData>;
  };
  filter(...params: Partial<TParams>): PlatformedQueryFilters;
}

// Given a query key pattern and a list of parameters, produce a concrete query key
function instantiateQueryKey<TQueryKeyPattern extends readonly QueryKeyPatternElement[]>(
  queryKeyPattern: TQueryKeyPattern,
  params: ExtractKeyParams<TQueryKeyPattern>,
): ConcreteQueryKey<TQueryKeyPattern> {
  let i = 0;
  return queryKeyPattern.map(item => (item === ParamSymbol ? params[i++] : item)) as ConcreteQueryKey<TQueryKeyPattern>;
}

// Given a query key pattern and a list of parameters, produce a concrete query key
function partiallyInstantiateQueryKey<TQueryKeyPattern extends readonly QueryKeyPatternElement[]>(
  queryKeyPattern: TQueryKeyPattern,
  params: Partial<ExtractKeyParams<TQueryKeyPattern>>,
): QueryKey {
  let i = 0;
  return queryKeyPattern
    .map(item => {
      if (item === ParamSymbol) {
        ++i;
        return i <= params.length ? params[i - 1] : undefined;
      } else {
        return i <= params.length ? item : undefined;
      }
    })
    .filter(item => item !== undefined);
}

// Given a query key pattern and a concrete query key, extract a list of parameters
function extractKeyParams<TQueryKeyPattern extends readonly QueryKeyPatternElement[]>(
  queryKeyPattern: TQueryKeyPattern,
  context: QueryFunctionContext<ConcreteQueryKey<TQueryKeyPattern>, never>,
): ExtractKeyParams<TQueryKeyPattern> {
  return context.queryKey.filter(
    (_item, i) => queryKeyPattern[i] === ParamSymbol,
  ) as ExtractKeyParams<TQueryKeyPattern>;
}

// Copy labels from one tuple type to another. `TFrom` must be at least a long as `TTo`.
type CopyLabels<TFrom extends unknown[], TTo extends unknown[]> = TFrom extends TTo
  ? TFrom
  : TFrom extends [...infer THead, any]
  ? CopyLabels<THead, TTo>
  : never;

type QueryKeyPatternElement = unknown | Param<any>;

const allQueryKeyPatterns: (readonly QueryKeyPatternElement[])[] = [];
function validateNonOverlappingKey(queryKeyPattern: readonly QueryKeyPatternElement[]) {
  for (const existingKeyPattern of allQueryKeyPatterns) {
    if (queryKeyPattern.length !== existingKeyPattern.length) {
      continue;
    }
    let overlapping = true;
    for (let i = 0; i < queryKeyPattern.length; i++) {
      const a = queryKeyPattern[i];
      const b = existingKeyPattern[i];
      if (a === ParamSymbol || b === ParamSymbol || hashKey([a]) === hashKey([b])) {
        continue;
      }

      overlapping = false;
    }
    if (overlapping) {
      const existingPretty = JSON.stringify(existingKeyPattern);
      const newPretty = JSON.stringify(queryKeyPattern);
      throw new Error(
        `Query key pattern overlaps with an existing query key pattern:\n${existingPretty}\n${newPretty}`,
      );
    }
  }
  allQueryKeyPatterns.push(queryKeyPattern);
}

// Define a new typed query
export function typedQuery<
  const TQueryKeyPattern extends readonly QueryKeyPatternElement[],
  const F extends (
    ...args: [...ExtractKeyParams<TQueryKeyPattern>, QueryFunctionContext<ConcreteQueryKey<TQueryKeyPattern>, never>]
  ) => Promise<any>,
  TData = Awaited<ReturnType<F>>,
  TParams extends ExtractKeyParams<TQueryKeyPattern> = CopyLabels<Parameters<F>, ExtractKeyParams<TQueryKeyPattern>>,
>(queryKeyPattern: TQueryKeyPattern, f: F): TypedQuery<TQueryKeyPattern, TData, TParams> {
  // Ensure this query key pattern won't conflict with any others
  validateNonOverlappingKey(queryKeyPattern);

  // Implement the query function by extracting parameters from the query key,
  // and then passing them to the provided implementation function.
  const queryFn = (context: QueryFunctionContext<ConcreteQueryKey<TQueryKeyPattern>, never>): Promise<TData> => {
    const params = extractKeyParams(queryKeyPattern, context);
    return f(...params, context);
  };
  // Return the concrete query key and the query function.
  const result = (...params: TParams) => ({
    queryKey: instantiateQueryKey(queryKeyPattern, params),
    queryFn,
  });
  // Attach the query key pattern as metadata to the result (current unused)
  result.queryKeyPattern = queryKeyPattern;
  // Attach a filter function to the result to allow easily filtering for any prefix of the query key.
  result.filter = (...params: Partial<TParams>) =>
    nominate("platformedQueryFilters", {
      queryKey: partiallyInstantiateQueryKey(queryKeyPattern, params),
    });
  return result;
}
