import {PlatformedQueryFilters, q, resetQueries} from "..";
import {nominate} from "../../Types";
import api from "../../api";
import {RedirectError} from "../../api/jsonApi";
import {RedirectTarget, redirectRestoringUrl} from "../../utils/auth";
import {param, typedQuery} from "../typing";

function authenticatedQueries(): PlatformedQueryFilters[] {
  return [
    nominate("platformedQueryFilters", {
      predicate(query) {
        return query.queryKey.length !== 1 || query.queryKey[0] !== "unauthenticated";
      },
    } as const),
  ];
}

export const whoAmI = typedQuery(["whoAmI"], async () => {
  // Important to use "reset" as "invalidate" doesn't reset error states
  await resetQueries([q.unauthenticated.filter()]);
  return await api.auth.whoAmI();
});

export const unauthenticated = typedQuery(["unauthenticated"], async () => {
  try {
    // Important to use "reset" as "invalidate" doesn't reset error states
    await resetQueries(authenticatedQueries());
    await api.auth.whoAmI();

    throw new RedirectError(redirectRestoringUrl());
  } catch (ex) {
    if (ex instanceof RedirectError && ex.response.target === RedirectTarget.Login) {
      return {};
    } else {
      throw ex;
    }
  }
});

export const accounts = typedQuery(["accounts"], async () => {
  return await api.auth.listAccounts();
});

export const consent = typedQuery(["consent", param("externalRedirect")], async externalRedirect => {
  return await api.accessTokens.consent({external_redirect: externalRedirect});
});
