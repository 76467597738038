import api from "../../api";
import {ErrorCatalogId} from "../../Types";
import {param, typedQuery} from "../typing";

export const reportedErrorsInCatalog = typedQuery(
  ["reportedErrorsInCatalog", param<ErrorCatalogId>("catalogId")],
  async catalogId => {
    return await api.reportedErrors.listInCatalog(catalogId);
  },
);
