/**
 * Pass a condition that is expected to be narrowed by TS to `never`, e.g. a
 * switch condition. Prevents compilation if not all cases are handled.
 */
export function unreachableCase(_c: never): never {
  throw new Error("Unreachable");
}

// Convert a promise-returning function, to a void-promise-returning function.
// Typescript doesn't do this conversion automatically, and we want to check that
// the argument types still match.
export function ignoreAsyncResult<A extends any[], R>(f: (...args: A) => Promise<R>): (...args: A) => Promise<void> {
  return f as any;
}

export type OptionalIfUndefined<T> = undefined extends T ? [params?: T] : [params: T];
