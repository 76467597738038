import {AccountId} from "../../../Types";
import {HTTPError} from "../../../api";
import externalApi from "../../../api/external";
import {param, typedQuery} from "../../typing";

export const whoAmI = typedQuery(["external", "whoAmI", param<AccountId>("accountId")], async accountId => {
  return await externalApi.auth.whoAmI(accountId);
});

export const isLoggedIn = typedQuery(["external", "isLoggedIn", param<AccountId>("accountId")], async accountId => {
  try {
    await externalApi.auth.whoAmI(accountId);
    return true;
  } catch (ex) {
    if (ex instanceof HTTPError && ex.response.status === 401) {
      return false;
    }
    throw ex;
  }
});
