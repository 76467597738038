import {FrameworkVersionId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const frameworkVersion = typedQuery(
  ["tprm", "frameworkVersion", param<FrameworkVersionId>("frameworkVersionId")],
  async frameworkVersionId => {
    return await api.tprm.frameworkVersions.get(frameworkVersionId);
  },
);

export const frameworkVersionProfiles = typedQuery(
  ["tprm", "frameworkVersionProfiles", param<FrameworkVersionId>("frameworkVersionId")],
  async frameworkVersionId => {
    return await api.tprm.frameworkVersions.listProfiles(frameworkVersionId);
  },
);
