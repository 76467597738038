import {param, typedQuery} from "../../typing.ts";
import api from "../../../api/index.ts";
import {EsignatureProviderConnectorType, EsignatureProviderId} from "../../../Types.ts";

export const esignatureProviderConnectors = typedQuery(["vendorToolkit", "esignatureProviderConnectors"], async () => {
  return await api.vendorToolkit.esignatureProviders.listConnectors();
});

export const esignatureProviderConnector = typedQuery(
  [
    "vendorToolkit",
    "esignatureProviderConnector",
    param<EsignatureProviderConnectorType>("esignatureProviderConnectorType"),
  ],
  async esignatureProviderConnectorType => {
    return await api.vendorToolkit.esignatureProviders.getConnector(esignatureProviderConnectorType);
  },
);

export const esignatureProviders = typedQuery(["vendorToolkit", "esignatureProviders"], async () => {
  return await api.vendorToolkit.esignatureProviders.list();
});

export const esignatureProvider = typedQuery(
  ["vendorToolkit", "esignatureProvider", param<EsignatureProviderId>("esignatureProviderId")],
  async esignatureProviderId => {
    return await api.vendorToolkit.esignatureProviders.get(esignatureProviderId);
  },
);
