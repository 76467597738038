import {Route, createRoutesFromElements, createBrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";

import {getFrontendEnvironment} from "../utils/environment";
import {CustomDomainType} from "../Types";
import BubbleError from "../BubbleError";
import {setRouter} from ".";
import {ReactNode} from "react";
import {unreachableCase} from "../utils/typescript";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function getDomainTypeFromSubdomain() {
  const [subdomain] = window.location.host.split(".", 2);
  switch (subdomain) {
    case "trust":
      return CustomDomainType.TrustCenter;
    case "collect":
      return CustomDomainType.Collect;
    default:
      return null;
  }
}

const domainType = getFrontendEnvironment().custom_domain?.domain_type ?? getDomainTypeFromSubdomain();

interface DynamicRouter {
  default: ReactNode;
}

function importRoutesFromDomainType(domainType: CustomDomainType | null): Promise<DynamicRouter> {
  switch (domainType) {
    case CustomDomainType.TrustCenter:
      return import("../TrustCenter");
    case CustomDomainType.Collect:
      return import("../Collect");
    case null:
      return import("../appRoutes");
    default:
      unreachableCase(domainType);
  }
}

export async function initRouter() {
  const routes = (await importRoutesFromDomainType(domainType)).default;
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<BubbleError />}>
        {routes}
      </Route>,
    ),
  );
  setRouter(router);
}
