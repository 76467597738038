import api from "../../../api";
import {param, typedQuery} from "../../typing";
import {ContentId, ShowcaseUpdateMessageId, UpdateNotificationStatus} from "../../../Types.ts";

export const trustCenterConfig = typedQuery(["vendorToolkit", "trustCenterConfig"], async () => {
  return await api.vendorToolkit.trustCenter.getConfig();
});

export const trustCenterAuth = typedQuery(["vendorToolkit", "trustCenterAuth"], async () => {
  return await api.vendorToolkit.trustCenter.getAuth();
});

export const trustCenterContents = typedQuery(["vendorToolkit", "trustCenterContents"], async () => {
  return await api.vendorToolkit.trustCenter.listContents();
});

export const trustCenterContent = typedQuery(
  ["vendorToolkit", "trustCenterContent", param<ContentId>("contentId")],
  async contentId => {
    return await api.vendorToolkit.trustCenter.getContent(contentId);
  },
);

export const trustCenterMailingList = typedQuery(["vendorToolkit", "trustCenterMailingList"], async () => {
  return await api.vendorToolkit.trustCenter.getMailingList();
});

export const trustCenterNewShowcaseMessages = typedQuery(
  ["vendorToolkit", "trustCenterNewShowcaseMessages"],
  async () => {
    return await api.vendorToolkit.trustCenter.listShowcaseMessages(UpdateNotificationStatus.New);
  },
);

export const trustCenterDraftShowcaseMessages = typedQuery(
  ["vendorToolkit", "trustCenterDraftShowcaseMessages"],
  async () => {
    return await api.vendorToolkit.trustCenter.listShowcaseMessages(UpdateNotificationStatus.Draft);
  },
);

export const trustCenterSentShowcaseMessages = typedQuery(
  ["vendorToolkit", "trustCenterSentShowcaseMessages"],
  async () => {
    return await api.vendorToolkit.trustCenter.listShowcaseMessages(UpdateNotificationStatus.Sent);
  },
);

export const trustCenterShowcaseMessage = typedQuery(
  ["vendorToolkit", "trustCenterShowcaseMessage", param<ShowcaseUpdateMessageId>("messageId")],
  async messageId => {
    return await api.vendorToolkit.trustCenter.getShowcaseMessage(messageId);
  },
);
