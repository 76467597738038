import {
  IObject,
  TprmQuestionnaireTemplate,
  TprmQuestionnaireTemplateId,
  TprmQuestionnaireTemplateMin,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function list(): Promise<TprmQuestionnaireTemplateMin[]> {
  return jsonApi.get("/tprm/questionnaire_templates");
}

export async function get(questionnaireTemplateId: TprmQuestionnaireTemplateId): Promise<TprmQuestionnaireTemplate> {
  return jsonApi.get(`/tprm/questionnaire_templates/${questionnaireTemplateId}`);
}

export async function createFromPreset(templates: IObject[]): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/questionnaire_templates/create_from_preset`, templates);
  await invalidateQueries([q.tprm.questionnaireTemplates.filter()]);
  return res;
}
