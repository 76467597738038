const IMPORT_ERROR_TS_STORAGE_KEY = "importErrorTimestamp";
const MINIMUM_REFRESH_INTERVAL = 15000;
export const TOAST_TRIGGER_STORAGE_KEY = "showAppUpdatedToast";

// When we deploy a change, it can cause the JS module names to change. This function is used to
// catch these errors and reload the page to get the updated module names.
export async function handleImportError(e: any): Promise<never> {
  const lastErrorTsStr = sessionStorage.getItem(IMPORT_ERROR_TS_STORAGE_KEY);
  let lastErrorTs = null;
  try {
    lastErrorTs = lastErrorTsStr ? parseFloat(lastErrorTsStr) : null;
  } catch {
    // Do nothing
  }
  const currentTs = Date.now();

  if (lastErrorTs == null || currentTs - lastErrorTs > MINIMUM_REFRESH_INTERVAL) {
    sessionStorage.setItem(IMPORT_ERROR_TS_STORAGE_KEY, currentTs.toString());
    sessionStorage.setItem(TOAST_TRIGGER_STORAGE_KEY, "true");
    window.location.reload();
  }

  // Give the page some time to reload
  await new Promise(resolve => setTimeout(resolve, 5000));

  throw e;
}

(window as any).testImportError = handleImportError;
