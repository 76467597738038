import {HTTPError} from ".";
import {UnauthorizedError} from "../Types";
import {
  RedirectResponse,
  RedirectTarget,
  getAccountId,
  redirectToAccountSelector,
  redirectUnauthorized,
} from "../utils/auth";
import {getFrontendEnvironment} from "../utils/environment";

// TODO: Once we can make typeshare output readonly types we should uncomment this
//export type Json = null | boolean | number | string | readonly Json[] | {readonly [key: string]: Json};
export type Json = unknown;
export type ApiOptions = {
  handleAuthErrors: boolean;
  headers?: HeadersInit;
  prettify?: boolean;
};

export const DEFAULT_API_OPTIONS: ApiOptions = {
  handleAuthErrors: true,
};

export class RedirectError extends Error {
  response: RedirectResponse;
  constructor(response: RedirectResponse);
  constructor(location: string, target?: RedirectTarget);
  constructor(response: RedirectResponse | string, target?: RedirectTarget) {
    const resp = typeof response === "string" ? new RedirectResponse(response, target) : response;
    super(`Redirecting to ${resp.location}`);
    this.response = resp;
  }
  toString() {
    return `Redirecting to ${this.response.location}`;
  }
  get _isNominal() {
    return true;
  }
}

export async function handleResponseError(response: Response, options = DEFAULT_API_OPTIONS) {
  const release = response.headers.get("X-Release");
  // If we're out of date, reload the page
  if (release && getFrontendEnvironment().release !== release) {
    window.location.reload();
  }
  if (!response.ok) {
    if (options.handleAuthErrors) {
      if (response.status === 401) {
        const err = new UnauthorizedError(await response.json());
        if (err.data.type === "MissingAuthorization") {
          throw new RedirectError(redirectUnauthorized());
        } else {
          throw err;
        }
      } else if (response.status === 422) {
        const json = await response.clone().json();
        if (json === "Unknown account") {
          throw new RedirectError(redirectToAccountSelector());
        }
      }
    }
    throw new HTTPError(response);
  }
}

export const addAccountId = (path: string): string => {
  const [p, s] = path.split("?");
  const params = new URLSearchParams(s);
  const account_id = getAccountId();
  if (account_id !== null) {
    params.set("account_id", account_id);
  }
  return `${p}?${params.toString()}`;
};

// Automatically retry a `fetch` request when the server indicates that a retry is possible
export async function fetchWithRetry(input: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
  for (let attempt = 1; ; ++attempt) {
    const request = new Request(input, init);
    request.headers.set("attempt", attempt.toString());
    const response = await fetch(request);
    const retryAfterStr = response.headers.get("retry-after");
    if (response.status !== 503 || !retryAfterStr) {
      return response;
    }
    const retryAfter = parseInt(retryAfterStr);
    await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));
  }
}

async function get<T extends Json>(path: string, options = DEFAULT_API_OPTIONS): Promise<T> {
  path = addAccountId(path);
  const resp = await fetchWithRetry(`/api${path}`, {
    headers: {
      Accept: "application/json",
      ...(options.headers ?? {}),
    },
  });
  await handleResponseError(resp, options);

  return await resp.json();
}

async function post<T extends Json>(path: string, body: Json = {}, options = DEFAULT_API_OPTIONS): Promise<T> {
  path = addAccountId(path);
  const resp = await fetchWithRetry(`/api${path}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(options.headers ?? {}),
    },
    body: JSON.stringify(body, undefined, options.prettify ? "  " : undefined),
  });
  await handleResponseError(resp, options);

  return await resp.json();
}

async function put<T extends Json>(path: string, body: Json = {}, options = DEFAULT_API_OPTIONS): Promise<T> {
  path = addAccountId(path);
  const resp = await fetchWithRetry(`/api${path}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(options.headers ?? {}),
    },
    body: JSON.stringify(body),
  });
  await handleResponseError(resp, options);

  return await resp.json();
}

async function patch<T extends Json>(path: string, body: Json = {}, options = DEFAULT_API_OPTIONS): Promise<T> {
  path = addAccountId(path);
  const resp = await fetchWithRetry(`/api${path}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(options.headers ?? {}),
    },
    body: JSON.stringify(body),
  });
  await handleResponseError(resp, options);

  return await resp.json();
}

async function delete_<T extends Json>(path: string, options = DEFAULT_API_OPTIONS): Promise<T> {
  path = addAccountId(path);
  const resp = await fetchWithRetry(`/api${path}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      ...(options.headers ?? {}),
    },
  });
  await handleResponseError(resp, options);

  return await resp.json();
}

function encodeQuery(args: object): string {
  return new URLSearchParams(Object.entries(args).map(([k, v]) => [k, `${v}`])).toString();
}

const jsonApi = {get, post, put, patch, delete_, encodeQuery};

export default jsonApi;
