import {
  ContentId,
  CreateOrUpdateShowcaseUpdateMessage,
  CreateTrustCenterContent,
  IObject,
  MailingList,
  ShowcaseMessageIds,
  ShowcaseUpdateMessage,
  ShowcaseUpdateMessageId,
  TrustCenterConfig,
  TrustCenterConfigMeta,
  TrustCenterContent,
  TrustCenterContentMin,
  UpdateNotificationStatus,
  TrustCenterAuth,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function getConfig() {
  return await jsonApi.get<TrustCenterConfig>("/vendor_toolkit/trust_center/config");
}

export async function getAuth() {
  return await jsonApi.get<TrustCenterAuth>("/vendor_toolkit/trust_center/auth");
}

export async function updateIsPublic(isPublic: boolean) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/auth/is_public`, isPublic);
  await invalidateQueries([q.vendorToolkit.trustCenterAuth.filter()]);
  return res;
}

export async function updatePassword(password: string | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/auth/password`, password);
  await invalidateQueries([q.vendorToolkit.trustCenterAuth.filter()]);
  return res;
}

export async function updateConfigDocumentRequestEmail(email: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/config/document_request_email`, email);
  await invalidateQueries([q.vendorToolkit.trustCenterConfig.filter()]);
  return res;
}

export async function updateConfigTheme(theme: IObject) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/config/theme`, theme);
  await invalidateQueries([q.vendorToolkit.trustCenterConfig.filter()]);
  return res;
}

export async function updateConfigMeta(meta: TrustCenterConfigMeta) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/config/meta`, meta);
  await invalidateQueries([
    q.vendorToolkit.trustCenterConfig.filter(),
    q.vendorToolkit.trustCenterNewShowcaseMessages.filter(),
  ]);
  return res;
}

export async function listContents() {
  return await jsonApi.get<TrustCenterContentMin[]>("/vendor_toolkit/trust_center/contents");
}

export async function getContent(contentId: ContentId) {
  return await jsonApi.get<TrustCenterContent>(`/vendor_toolkit/trust_center/contents/${contentId}`);
}

export async function createContent(content: CreateTrustCenterContent) {
  const res = await jsonApi.post<TrustCenterContent>("/vendor_toolkit/trust_center/contents", content);
  await invalidateQueries([q.vendorToolkit.trustCenterContents.filter()]);
  return res;
}

export async function updateContentTitle(contentId: ContentId, title: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/contents/${contentId}/title`, title);
  await invalidateQueries([
    q.vendorToolkit.trustCenterContents.filter(),
    q.vendorToolkit.trustCenterContent.filter(contentId),
  ]);
  return res;
}

export async function updateContentContent(contentId: ContentId, content: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/contents/${contentId}/content`, content);
  await invalidateQueries([
    q.vendorToolkit.trustCenterContent.filter(contentId),
    q.vendorToolkit.trustCenterNewShowcaseMessages.filter(),
  ]);
  return res;
}

export async function deleteContent(contentId: ContentId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/trust_center/contents/${contentId}`);
  await invalidateQueries([q.vendorToolkit.trustCenterContents.filter()]);
  return res;
}

export async function getMailingList() {
  return await jsonApi.get<MailingList>("/vendor_toolkit/trust_center/mailing_list");
}

export async function createShowcaseMessage() {
  const res = await jsonApi.post<ShowcaseUpdateMessageId>(
    "/vendor_toolkit/trust_center/notification_center/messages",
    {},
  );
  await invalidateQueries([q.vendorToolkit.trustCenterDraftShowcaseMessages.filter()]);
  return res;
}

export async function updateShowcaseMessage(
  messageId: ShowcaseUpdateMessageId,
  saveRequest: CreateOrUpdateShowcaseUpdateMessage,
) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/trust_center/notification_center/messages/${messageId}`,
    saveRequest,
  );
  await invalidateQueries([q.vendorToolkit.trustCenterShowcaseMessage.filter(messageId)]);
  return res;
}

export async function sendShowcaseMessage(messageId: ShowcaseUpdateMessageId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/notification_center/messages/${messageId}/send`);
  await invalidateQueries([
    q.vendorToolkit.trustCenterShowcaseMessage.filter(messageId),
    q.vendorToolkit.trustCenterDraftShowcaseMessages.filter(),
    q.vendorToolkit.trustCenterSentShowcaseMessages.filter(),
    q.vendorToolkit.trustCenterNewShowcaseMessages.filter(),
  ]);
  return res;
}

export async function readShowcaseMessage(messageId: ShowcaseUpdateMessageId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/trust_center/notification_center/messages/${messageId}/read`);
  await invalidateQueries([
    q.vendorToolkit.trustCenterShowcaseMessage.filter(messageId),
    q.vendorToolkit.trustCenterNewShowcaseMessages.filter(),
    q.vendorToolkit.trustCenterDraftShowcaseMessages.filter(),
    q.vendorToolkit.trustCenterSentShowcaseMessages.filter(),
  ]);
  return res;
}

export async function getShowcaseMessage(messageId: ShowcaseUpdateMessageId) {
  return await jsonApi.get<ShowcaseUpdateMessage>(
    `/vendor_toolkit/trust_center/notification_center/messages/${messageId}`,
  );
}

export async function listShowcaseMessages(status: UpdateNotificationStatus) {
  return await jsonApi.get<ShowcaseMessageIds>(
    `/vendor_toolkit/trust_center/notification_center/messages/list?${jsonApi.encodeQuery({status})}`,
  );
}

export async function deleteShowcaseMessage(messageId: ShowcaseUpdateMessageId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/trust_center/notification_center/messages/${messageId}`);
  await invalidateQueries([
    q.vendorToolkit.trustCenterShowcaseMessage.filter(messageId),
    q.vendorToolkit.trustCenterNewShowcaseMessages.filter(),
    q.vendorToolkit.trustCenterDraftShowcaseMessages.filter(),
    q.vendorToolkit.trustCenterSentShowcaseMessages.filter(),
  ]);
  return res;
}
