export type Json = null | boolean | number | string | JsonArray | JsonObject
export type JsonObject = { readonly [key: string]: Json }
export type JsonArray = readonly Json[]

export type RpcClientRequestMessage = {
  type: "request"
  requestId: number
  requestType: string
  payload: Json
}

export type RpcClientMessage =
  | RpcClientRequestMessage
  | {
      type: "hello"
    }

export type RpcServerResponse =
  | {
      success: true
      payload: Json
    }
  | {
      success: false
      error: string
    }

export type RpcServerMessage =
  | {
      type: "hello"
      version: number
    }
  | {
      type: "event"
      payload: Json
    }
  | {
      type: "response"
      requestId: number
      response: RpcServerResponse
    }

export const UNVERSIONED = 0
