import {OwnerId, Owner, ResolveOwner} from "../Types";
import jsonApi from "./jsonApi";

export async function resolve(resolveOwners: ResolveOwner[]) {
  return await jsonApi.post<Owner[]>(`/owners/resolve`, resolveOwners);
}

export async function get(ownerId: OwnerId) {
  return await jsonApi.get<Owner>(`/owners/${ownerId}`);
}
