import {tabsAnatomy as parts} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers, cssVar} from "@chakra-ui/styled-system";
const {defineMultiStyleConfig, definePartsStyle} = createMultiStyleConfigHelpers(parts.keys);
const $fg = cssVar("tabs-color");
const $bg = cssVar("tabs-bg");
const variantEnclosedColored = definePartsStyle(props => {
  const {colorScheme: c} = props;
  return {
    tab: {
      border: "1px solid",
      borderColor: "inherit",
      [$bg.variable]: "colors.gray.50",
      _dark: {
        [$bg.variable]: "colors.whiteAlpha.50",
      },
      mt: "-1px",
      _notLast: {
        marginEnd: "-1px",
      },
      _selected: {
        [$bg.variable]: "colors.white",
        [$fg.variable]: `colors.${c}.600`,
        _dark: {
          [$bg.variable]: "colors.gray.800",
          [$fg.variable]: `colors.${c}.300`,
        },
        borderColor: "inherit",
        borderBottomColor: "currentColor",
        borderTopColor: "transparent",
      },
      color: $fg.reference,
      bg: $bg.reference,
    },
    tablist: {
      mt: "-1px",
      borderTop: "1px solid",
      borderColor: "inherit",
    },
  };
});

const variants = {
  "enclosed-colored-flipped": variantEnclosedColored,
};

export default defineMultiStyleConfig({
  variants,
});
