import {HTTPError} from "../../../api";
import externalApi from "../../../api/external";
import {GetGraphQuery, ContentId, AccountId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const trustCenterDocuments = typedQuery(
  ["external", "trustCenterDocuments", param<AccountId>("accountId")],
  async accountId => {
    return await externalApi.trustCenters.listDocuments(accountId);
  },
);

export const trustCenterConfig = typedQuery(
  ["external", "trustCenterConfig", param<AccountId>("accountId")],
  async accountId => {
    return await externalApi.trustCenters.getConfig(accountId);
  },
);

export const trustCenterMissingAuth = typedQuery(
  ["external", "trustCenterMissingAuth", param<AccountId>("accountId")],
  async accountId => {
    try {
      await externalApi.trustCenters.getConfig(accountId);
      return null;
    } catch (ex) {
      if (ex instanceof HTTPError && ex.response.status === 401) {
        const resp = await ex.response.json();
        return resp.type as "MissingAuthorization" | "IncorrectEmailOrPassword";
      }
      throw ex;
    }
  },
);

export const trustCenterFaq = typedQuery(
  ["external", "trustCenterFaq", param<AccountId>("accountId")],
  async accountId => {
    return await externalApi.trustCenters.getFaq(accountId);
  },
);

export const trustCenterAiFaq = typedQuery(
  ["external", "trustCenterAiFaq", param<AccountId>("accountId")],
  async accountId => {
    return await externalApi.trustCenters.getAiFaq(accountId);
  },
);

export const trustCenterContent = typedQuery(
  ["external", "trustCenterContent", param<AccountId>("accountId"), param<ContentId>("contentId")],
  async (accountId, contentId) => {
    return await externalApi.trustCenters.getContent(accountId, contentId);
  },
);

export const trustCenterGraph = typedQuery(
  ["external", "trustCenterGraph", param<AccountId>("accountId"), param<GetGraphQuery>("query")],
  async (accountId, query) => {
    return await externalApi.trustCenters.getGraph(accountId, query);
  },
);

export const trustCenterSchema = typedQuery(
  ["external", "trustCenterSchema", param<AccountId>("accountId")],
  async accountId => {
    return await externalApi.trustCenters.getSchema(accountId);
  },
);
