import * as batshit from "@yornaath/batshit";
import {ControlId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";
import {resolver, scheduler} from "../../batch";

const controls = batshit.create({
  fetcher: api.tprm.controls.load,
  resolver: resolver("control_id"),
  scheduler,
});

export const control = typedQuery(["tprm", "control", param<ControlId>("controlId")], async controlId => {
  return await controls.fetch(controlId);
});
