import {AdHocQuestion, CreateAdHocQuestion, QuestionId} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function get(questionId: QuestionId) {
  return await jsonApi.get<AdHocQuestion>(`/vendor_toolkit/adhoc_questions/${questionId}`);
}

export async function list() {
  return await jsonApi.get<AdHocQuestion[]>(`/vendor_toolkit/adhoc_questions`);
}

export async function create(question: CreateAdHocQuestion) {
  const res = await jsonApi.post<AdHocQuestion>(`/vendor_toolkit/adhoc_questions`, question);
  await invalidateQueries([q.vendorToolkit.adhocQuestions.filter(), q.vendorToolkit.tasks.filter()]);
  return res;
}
