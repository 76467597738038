import {param, typedQuery} from "../../typing";
import externalApi from "../../../api/external";
import {AccountId, TprmExternalQuestionnaireId} from "../../../Types";
import {HTTPError} from "../../../api";

export const externalQuestionnaire = typedQuery(
  [
    "external",
    "collect",
    "externalQuestionnaire",
    param<AccountId>("accountId"),
    param<TprmExternalQuestionnaireId>("externalQuestionnaireId"),
  ],
  async (accountId, externalQuestionnaireId) => {
    return await externalApi.collect.getExternalQuestionnaire(accountId, externalQuestionnaireId);
  },
);

export const externalQuestionnaireAccessible = typedQuery(
  [
    "external",
    "collect",
    "externalQuestionnaireAccessible",
    param<AccountId>("accountId"),
    param<TprmExternalQuestionnaireId>("externalQuestionnaireId"),
  ],
  async (accountId, externalQuestionnaireId) => {
    try {
      await externalApi.collect.getExternalQuestionnaire(accountId, externalQuestionnaireId);
      return true;
    } catch (ex) {
      if (ex instanceof HTTPError && ex.response.status === 401) {
        return false;
      }
      throw ex;
    }
  },
);

export const externalQuestionnaireUsers = typedQuery(
  [
    "external",
    "collect",
    "externalQuestionnaireUsers",
    param<AccountId>("accountId"),
    param<TprmExternalQuestionnaireId>("externalQuestionnaireId"),
  ],
  async (accountId, externalQuestionnaireId) => {
    return await externalApi.collect.listExternalQuestionnaireUsers(accountId, externalQuestionnaireId);
  },
);

export const template = typedQuery(
  [
    "external",
    "collect",
    "externalQuestionnaire",
    "template",
    param<AccountId>("accountId"),
    param<TprmExternalQuestionnaireId>("externalQuestionnaireId"),
  ],
  async (accountId, externalQuestionnaireId) => {
    return await externalApi.collect.getExternalQuestionnaireTemplate(accountId, externalQuestionnaireId);
  },
);
