import {useLocalStorage} from "usehooks-ts";
import {Json} from "../../api/jsonApi";
import {useClientAccount} from "./clientAccount";
import {AccountId} from "../../Types";

function clientAccountLocalStorageKey(accountId: AccountId, storageKey: string) {
  return `p4dclientaccountid-${accountId}:${storageKey}`;
}

export function useClientAccountLocalStorage<S extends Json>(
  storageKey: string,
  defaultValue: S,
): [S, (newValue: S | ((oldValue: S) => S)) => void] {
  const account = useClientAccount();
  return useLocalStorage(clientAccountLocalStorageKey(account.account_id, storageKey), defaultValue);
}

export function getClientAccountLocalStorage<S extends Json>(
  accountId: AccountId,
  storageKey: string,
  defaultValue: S,
) {
  try {
    return JSON.parse(localStorage.getItem(clientAccountLocalStorageKey(accountId, storageKey)) ?? "");
  } catch {
    return defaultValue;
  }
}
