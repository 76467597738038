import {
  InAppNotificationId,
  InAppNotification,
  InAppNotificationStream,
  ProductType,
  ListNotificationsArgs,
  NotificationStatus,
} from "../../Types.ts";
import jsonApi from "../jsonApi.ts";
import {invalidateQueries, q} from "../../state";

export async function list(
  productType: ProductType,
  args: ListNotificationsArgs,
  status: NotificationStatus,
): Promise<InAppNotification[]> {
  return await jsonApi.get<InAppNotification[]>(`/notifications/${productType}/${status}?${jsonApi.encodeQuery(args)}`);
}

export async function channelStream(productType: ProductType): Promise<InAppNotificationStream> {
  return await jsonApi.get<InAppNotificationStream>(`/notifications/channel_stream/${productType}`);
}

export async function markAsRead(notification_ids: InAppNotificationId[]) {
  const res = await jsonApi.post<"ok">(`/notifications/mark_as_read`, notification_ids);
  await invalidateQueries([q.notifications.filter(), q.notificationStream.filter()]);
  return res;
}

export async function archive(notification_id: InAppNotificationId) {
  const res = await jsonApi.delete_<"ok">(`/notifications/${notification_id}/archive`);
  await invalidateQueries([q.notifications.filter(), q.notificationStream.filter()]);
  return res;
}

export async function archiveAll() {
  const res = await jsonApi.delete_<"ok">(`/notifications/all`);
  await invalidateQueries([q.notifications.filter(), q.notificationStream.filter()]);
  return res;
}
