import {ThirdPartyId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const thirdParty = typedQuery(
  ["tprm", "thirdParty", param<ThirdPartyId>("thirdPartyId")],
  async thirdPartyId => {
    return await api.tprm.thirdParties.get(thirdPartyId);
  },
);

export const thirdParties = typedQuery(["tprm", "thirdParties"], async () => {
  return await api.tprm.thirdParties.list();
});

export const thirdPartyDataRooms = typedQuery(
  ["tprm", "thirdPartyDataRooms", param<ThirdPartyId>("thirdPartyId")],
  async thirdPartyId => {
    return await api.tprm.thirdParties.listDataRooms(thirdPartyId);
  },
);

export const thirdPartyExternalQuestionnaires = typedQuery(
  ["tprm", "thirdPartyExternalQuestionnaires", param<ThirdPartyId>("thirdPartyId")],
  async thirdPartyId => {
    return await api.tprm.thirdParties.listExternalQuestionnaires(thirdPartyId);
  },
);
