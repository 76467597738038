import {invalidateQueries, q} from "../../state";
import {CreateThirdPartyStatus, ThirdPartyStatusId, ThirdPartyStatusMin} from "../../Types";
import jsonApi from "../jsonApi";

export async function list(): Promise<ThirdPartyStatusMin[]> {
  return await jsonApi.get<ThirdPartyStatusMin[]>("/tprm/third_party_statuses");
}

export async function create(status: CreateThirdPartyStatus) {
  const res = await jsonApi.post<"ok">("/tprm/third_party_statuses", status);
  await invalidateQueries([q.tprm.thirdPartyStatuses.filter()]);
  return res;
}

export async function updateTitle(statusId: ThirdPartyStatusId, title: string) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_statuses/${statusId}/title`, title);
  await invalidateQueries([q.tprm.thirdPartyStatuses.filter()]);
  return res;
}

export async function updateColor(statusId: ThirdPartyStatusId, color: string) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_statuses/${statusId}/color`, color);
  await invalidateQueries([q.tprm.thirdPartyStatuses.filter()]);
  return res;
}

export async function reorder(statusId: ThirdPartyStatusId, ordinal: number) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_statuses/${statusId}/ordinal`, ordinal);
  await invalidateQueries([q.tprm.thirdPartyStatuses.filter()]);
  return res;
}

export async function delete_(statusId: ThirdPartyStatusId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/third_party_statuses/${statusId}`);
  await invalidateQueries([q.tprm.thirdPartyStatuses.filter()]);
  return res;
}
