import {
  Fact,
  Paginated,
  FactCluster,
  ClusteredFact,
  FactMin,
  Resolution,
  OwnerId,
  FactClusterId,
  ResolutionId,
  FactSearchArgs,
  FactId,
  ScopedLibrarySectionId,
  CountResolutionsArgs,
  ListResolutionsArgs,
  ResolutionMin,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function search(args: FactSearchArgs) {
  return await jsonApi.post<Paginated<Fact>>(`/vendor_toolkit/facts/search`, args);
}

export async function updateDisabled(factId: FactId, disabled: boolean) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/facts/${factId}/disabled`, disabled);
  await invalidateQueries([
    q.vendorToolkit.facts.filter(),
    q.vendorToolkit.documentFacts.filter(),
    q.vendorToolkit.questionUsedFacts.filter(),
    q.vendorToolkit.questionGeneratedFacts.filter(),
    q.vendorToolkit.resolutions.filter(),
    q.vendorToolkit.resolution.filter(),
  ]);
  return res;
}

export async function updateLibrarySection(factId: FactId, scopedId: ScopedLibrarySectionId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/facts/${factId}/library_section`, scopedId);
  await invalidateQueries([
    q.vendorToolkit.facts.filter(),
    q.vendorToolkit.documentFacts.filter(),
    q.vendorToolkit.questionUsedFacts.filter(),
    q.vendorToolkit.questionGeneratedFacts.filter(),
    q.vendorToolkit.resolutions.filter(),
    q.vendorToolkit.resolvedResolutions.filter(),
    q.vendorToolkit.ignoredResolutions.filter(),
    q.vendorToolkit.resolution.filter(),
  ]);
  return res;
}

export async function listTopLevelClusters() {
  return await jsonApi.get<FactCluster[]>(`/vendor_toolkit/fact_clusters`);
}

export async function listEquivalences(clusterId: FactClusterId) {
  return await jsonApi.get<FactMin[]>(`/vendor_toolkit/fact_clusters/${clusterId}/equivalences`);
}

export async function listFactsInCluster(clusterId: FactClusterId) {
  return await jsonApi.get<ClusteredFact[]>(`/vendor_toolkit/fact_clusters/${clusterId}/facts`);
}

export async function listResolutions(args: ListResolutionsArgs) {
  return await jsonApi.get<Paginated<ResolutionMin>>(`/vendor_toolkit/resolutions?${jsonApi.encodeQuery(args)}`);
}

export async function countResolutions(args: CountResolutionsArgs) {
  return await jsonApi.get<number>(`/vendor_toolkit/resolutions/count?${jsonApi.encodeQuery(args)}`);
}

export async function listResolvedResolutions() {
  return await jsonApi.get<ResolutionMin[]>(`/vendor_toolkit/resolved_resolutions`);
}

export async function listIgnoredResolutions() {
  return await jsonApi.get<ResolutionMin[]>(`/vendor_toolkit/ignored_resolutions`);
}

export async function getResolution(resolutionId: ResolutionId) {
  return await jsonApi.get<Resolution>(`/vendor_toolkit/resolutions/${resolutionId}`);
}

export async function resolveResolution(resolutionId: ResolutionId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/resolutions/${resolutionId}/resolve`);
  await invalidateQueries([
    q.vendorToolkit.resolutions.filter(),
    q.vendorToolkit.resolutionsCount.filter(),
    q.vendorToolkit.resolvedResolutions.filter(),
    q.vendorToolkit.ignoredResolutions.filter(),
    q.vendorToolkit.resolution.filter(resolutionId),
  ]);
  return res;
}

export async function ignoreResolution(resolutionId: ResolutionId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/resolutions/${resolutionId}/ignore`);
  await invalidateQueries([
    q.vendorToolkit.resolutions.filter(),
    q.vendorToolkit.resolutionsCount.filter(),
    q.vendorToolkit.resolvedResolutions.filter(),
    q.vendorToolkit.ignoredResolutions.filter(),
    q.vendorToolkit.resolution.filter(resolutionId),
  ]);
  return res;
}

export async function revertResolution(resolutionId: ResolutionId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/resolutions/${resolutionId}/revert`);
  await invalidateQueries([
    q.vendorToolkit.resolutions.filter(),
    q.vendorToolkit.resolutionsCount.filter(),
    q.vendorToolkit.resolvedResolutions.filter(),
    q.vendorToolkit.ignoredResolutions.filter(),
    q.vendorToolkit.resolution.filter(resolutionId),
  ]);
  return res;
}

export async function assignResolution(resolutionId: ResolutionId, ownerId: OwnerId | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/resolutions/${resolutionId}/assign`, ownerId);
  await invalidateQueries([
    q.vendorToolkit.resolutions.filter(),
    q.vendorToolkit.resolvedResolutions.filter(),
    q.vendorToolkit.ignoredResolutions.filter(),
    q.vendorToolkit.resolution.filter(resolutionId),
  ]);
  return res;
}

export async function updateResolutionIsInternal(resolutionId: ResolutionId, isInternal: boolean) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/resolutions/${resolutionId}/is_internal`, isInternal);
  await invalidateQueries([
    q.vendorToolkit.resolutions.filter(),
    q.vendorToolkit.resolutionsCount.filter(),
    q.vendorToolkit.resolvedResolutions.filter(),
    q.vendorToolkit.ignoredResolutions.filter(),
    q.vendorToolkit.resolution.filter(resolutionId),
  ]);
  return res;
}
