import api from "../../../api";
import {DocumentSourceId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const documentSource = typedQuery(
  ["vendorToolkit", "documentSource", param<DocumentSourceId>("documentSourceId")],
  async documentSourceId => {
    return await api.vendorToolkit.documentSources.get(documentSourceId);
  },
);

export const documentSources = typedQuery(["vendorToolkit", "documentSources"], async () => {
  return await api.vendorToolkit.documentSources.list();
});

export const documentSourceConnector = typedQuery(
  ["vendorToolkit", "documentSourceConnector", param("documentSourceConnectorId")],
  async documentSourceConnectorId => {
    return await api.vendorToolkit.documentSources.getConnector(documentSourceConnectorId);
  },
);

export const documentSourceConnectors = typedQuery(["vendorToolkit", "documentSourceConnectors"], async () => {
  return await api.vendorToolkit.documentSources.listConnectors();
});
