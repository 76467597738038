import {CreateAccessToken, AccessToken, ConsentResponse, ConsentArgs} from "../Types";
import jsonApi from "./jsonApi";

export async function create(accessToken: CreateAccessToken) {
  return await jsonApi.post<AccessToken>("/access_tokens", accessToken);
}

export async function consent(args: ConsentArgs) {
  return await jsonApi.get<ConsentResponse>(`/consent?${jsonApi.encodeQuery(args)}`);
}
