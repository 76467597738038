import {AccountId} from "../../../Types";
import externalApi from "../../../api/external";
import {param, typedQuery} from "../../typing";

export const account = typedQuery(["external", "account", param<AccountId>("accountId")], async accountId => {
  return await externalApi.accounts.get(accountId);
});

export const accountBySlug = typedQuery(["external", "accountBySlug", param("accountSlug")], async accountSlug => {
  return await externalApi.accounts.getBySlug(accountSlug);
});
