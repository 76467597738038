import * as batshit from "@yornaath/batshit";
import api from "../../../api";
import {param, typedQuery} from "../../typing";
import {resolver, scheduler} from "../../batch";
import {QuestionId, LayerType} from "../../../Types";

const questions = batshit.create({
  fetcher: api.vendorToolkit.questions.load,
  resolver: resolver("question_id"),
  scheduler,
});

export const question = typedQuery(["vendorToolkit", "question", param<QuestionId>("questionId")], async questionId => {
  return await questions.fetch(questionId);
});

export const questionDocumentation = typedQuery(
  ["vendorToolkit", "questionDocumentation", param<QuestionId>("questionId"), param<LayerType>()],
  async (questionId, layerType) => {
    return await api.vendorToolkit.questions.listDocumentation(questionId, layerType);
  },
);

export const questionUsedFacts = typedQuery(
  ["vendorToolkit", "questionUsedFacts", param<QuestionId>("questionId")],
  async questionId => {
    return await api.vendorToolkit.questions.listUsedFacts(questionId);
  },
);

export const questionGeneratedFacts = typedQuery(
  ["vendorToolkit", "questionGeneratedFacts", param<QuestionId>("questionId")],
  async questionId => {
    return await api.vendorToolkit.questions.listGeneratedFacts(questionId);
  },
);

export const watchingQuestion = typedQuery(
  ["vendorToolkit", "watchingQuestion", param<QuestionId>("questionId")],
  async questionId => {
    return await api.vendorToolkit.questions.getWatching(questionId);
  },
);
