import {AccountId, ExternalWhoAmIResponse} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "./jsonApi";

export async function login(accountId: AccountId, token: string) {
  return await jsonApi.post<"ok">(`/accounts/${accountId}/login`, undefined, {
    headers: {Authorization: `Bearer ${token}`},
  });
}

export async function logout(accountId: AccountId) {
  await jsonApi.post<"ok">(`/accounts/${accountId}/logout`);
  await invalidateQueries([q.external.filter()]);
}

export async function whoAmI(accountId: AccountId): Promise<ExternalWhoAmIResponse> {
  return await jsonApi.get(`/accounts/${accountId}/whoami`);
}
