import {CreateQuestion, IdentifiedSheetSection} from "../../Types";
import jsonApi, {DEFAULT_API_OPTIONS} from "../jsonApi";

type Sheet = {[row: number]: {[col: number]: string}};

export async function identifySheetSections(sheet: Sheet): Promise<IdentifiedSheetSection[]> {
  return await jsonApi.post<IdentifiedSheetSection[]>("/vendor_toolkit/ai/identify_sheet_sections", sheet, {
    ...DEFAULT_API_OPTIONS,
    prettify: true,
  });
}

type Cell = {
  content: string | null;
  options?: string[];
};

type SheetSection = {
  section_title?: string;
  cells: Cell[][];
};

export async function extractSectionQuestions(section: SheetSection): Promise<CreateQuestion[]> {
  return await jsonApi.post<CreateQuestion[]>("/vendor_toolkit/ai/extract_section_questions", section, {
    ...DEFAULT_API_OPTIONS,
    prettify: true,
  });
}
