import {QuestionId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const adhocQuestion = typedQuery(
  ["vendorToolkit", "adhocQuestion", param<QuestionId>("questionId")],
  async questionId => {
    return await api.vendorToolkit.adhocQuestions.get(questionId);
  },
);

export const adhocQuestions = typedQuery(["vendorToolkit", "adhocQuestions"], async () => {
  return await api.vendorToolkit.adhocQuestions.list();
});
