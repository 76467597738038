import {CoreResponse, CoreResponseId, CreateCoreResponse, ScopeId} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function list(): Promise<CoreResponse[]> {
  return await jsonApi.get<CoreResponse[]>("/vendor_toolkit/core_responses");
}

export async function get(coreResponseId: CoreResponseId): Promise<CoreResponse> {
  return await jsonApi.get<CoreResponse>(`/vendor_toolkit/core_responses/${coreResponseId}`);
}

export async function create(payload: CreateCoreResponse): Promise<CoreResponse> {
  const res = await jsonApi.post<CoreResponse>("/vendor_toolkit/core_responses", payload);
  await invalidateQueries([q.vendorToolkit.coreResponses.filter()]);
  return res;
}

export async function delete_(coreResponseId: CoreResponseId) {
  await jsonApi.delete_(`/vendor_toolkit/core_responses/${coreResponseId}`);
  await invalidateQueries([
    q.vendorToolkit.coreResponses.filter(),
    q.vendorToolkit.coreResponse.filter(coreResponseId),
  ]);
}

export async function updateQuestionText(coreResponseId: CoreResponseId, questionText: string) {
  await jsonApi.post(`/vendor_toolkit/core_responses/${coreResponseId}/question_text`, questionText);
  await invalidateQueries([
    q.vendorToolkit.coreResponses.filter(),
    q.vendorToolkit.coreResponse.filter(coreResponseId),
  ]);
}

export async function updateResponseText(coreResponseId: CoreResponseId, responseText: string) {
  await jsonApi.post(`/vendor_toolkit/core_responses/${coreResponseId}/response_text`, responseText);
  await invalidateQueries([
    q.vendorToolkit.coreResponses.filter(),
    q.vendorToolkit.coreResponse.filter(coreResponseId),
  ]);
}

export async function updateLimitedToScopes(coreResponseId: CoreResponseId, scopeIds: ScopeId[] | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/core_responses/${coreResponseId}/limited_to_scopes`, scopeIds);
  await invalidateQueries([
    q.vendorToolkit.coreResponses.filter(),
    q.vendorToolkit.coreResponse.filter(coreResponseId),
  ]);
  return res;
}
