import {AccountMin, AccountId} from "../../Types";
import jsonApi from "./jsonApi";

export async function get(accountId: AccountId) {
  return await jsonApi.get<AccountMin>(`/accounts/${accountId}`);
}

export async function getBySlug(accountSlug: string) {
  return await jsonApi.get<AccountMin>(`/accounts_by_slug/${accountSlug}`);
}
