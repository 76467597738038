import {PlatformedQueryFilters} from "../..";
import {nominate} from "../../../Types";

export * from "./account";
export * from "./trustCenter";
export * as collect from "./collect";
export * as auth from "./auth";

export function filter(): PlatformedQueryFilters {
  return nominate("platformedQueryFilters", {queryKey: ["external"]});
}
