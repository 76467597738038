import * as accounts from "./accounts";
import * as collect from "./collect";
import * as trustCenters from "./trustCenters";
import * as auth from "./auth";

const externalApi = {
  accounts,
  collect,
  trustCenters,
  auth,
};

export default externalApi;
