import {FrameworkVersionMin, FrameworkVersionId, FrameworkProfileMin} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function get(frameworkVersionId: FrameworkVersionId): Promise<FrameworkVersionMin> {
  return jsonApi.get(`/tprm/framework_versions/${frameworkVersionId}`);
}

export async function listProfiles(frameworkVersionId: FrameworkVersionId): Promise<FrameworkProfileMin[]> {
  return jsonApi.get(`/tprm/framework_versions/${frameworkVersionId}/profiles`);
}

export async function delete_(frameworkVersionId: FrameworkVersionId): Promise<string> {
  const res = await jsonApi.delete_<"ok">(`/tprm/framework_versions/${frameworkVersionId}`);
  await invalidateQueries([q.tprm.frameworks.filter()]);
  await invalidateQueries([q.tprm.frameworkVersions.filter()]);
  return res;
}

export async function updateVersion(frameworkVersionId: FrameworkVersionId, version: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/framework_versions/${frameworkVersionId}/version`, version);
  await invalidateQueries([q.tprm.frameworks.filter()]);
  await invalidateQueries([q.tprm.frameworkVersions.filter()]);
  return res;
}
