import {
  AccountId,
  AssetId,
  ContentId,
  DocumentAccessRequest,
  DocumentExternal,
  DocumentId,
  GetGraphQuery,
  GraphProperty,
  MailSubscriptionId,
  SARRequest,
  SubGraph,
  SubscribeRequest,
  SubscriptionAction,
  TrustCenterConfigMin,
  TrustCenterContent,
  TrustCenterFaq,
} from "../../Types";
import jsonApi, {API_BASE} from "./jsonApi";
import {invalidateQueries, q} from "../../state";
import {getTrustCenterPassword} from "../../TrustCenter/hooks/accessPassword";

function basicAuth(accountId: AccountId, overridePassword?: string): {Authorization: string} {
  const password = overridePassword ?? getTrustCenterPassword(accountId);
  const token = btoa(`${accountId}:${password}`);
  return {Authorization: `Basic ${token}`};
}

function basicAuthUrl(accountId: AccountId, url: string): string {
  const urlObj = new URL(url);
  const auth = basicAuth(accountId).Authorization;
  if (auth) {
    urlObj.searchParams.append("auth", auth);
  }
  return urlObj.toString();
}

export async function listDocuments(accountId: AccountId) {
  return await jsonApi.get<DocumentExternal[]>(`/accounts/${accountId}/showcase/documents`, {
    headers: basicAuth(accountId),
  });
}

export const downloadDocument = async (accountId: AccountId, documentId: DocumentId) => {
  const a = document.createElement("a");
  a.href = basicAuthUrl(accountId, `${API_BASE}/accounts/${accountId}/showcase/documents/${documentId}/download`);
  a.click();
};

export async function requestDocumentAccess(
  accountId: AccountId,
  documentId: DocumentId,
  documentRequest: DocumentAccessRequest,
) {
  return await jsonApi.post<"ok">(
    `/accounts/${accountId}/showcase/documents/${documentId}/request_access`,
    documentRequest,
    {
      headers: basicAuth(accountId),
    },
  );
}

export const getAssetUrl = (accountId: AccountId, assetId: AssetId) => {
  return basicAuthUrl(accountId, `${API_BASE}/accounts/${accountId}/showcase/assets/${assetId}/download`);
};

export const getContent = async (accountId: AccountId, contentId: ContentId) => {
  return await jsonApi.get<TrustCenterContent>(`/accounts/${accountId}/showcase/contents/${contentId}`, {
    headers: basicAuth(accountId),
  });
};

export const getConfig = async (accountId: AccountId, overridePassword?: string) => {
  return await jsonApi.get<TrustCenterConfigMin>(`/accounts/${accountId}/showcase/config`, {
    headers: basicAuth(accountId, overridePassword),
  });
};

export const getFaq = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterFaq>(`/accounts/${accountId}/showcase/faq`, {
    headers: basicAuth(accountId),
  });
};

export const getAiFaq = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterFaq>(`/accounts/${accountId}/showcase/ai_faq`, {
    headers: basicAuth(accountId),
  });
};

export const getGraph = async (accountId: AccountId, query: GetGraphQuery) => {
  return await jsonApi.get<SubGraph>(`/accounts/${accountId}/showcase/graph?${new URLSearchParams(query as any)}`, {
    headers: basicAuth(accountId),
  });
};

export const getSchema = async (accountId: AccountId) => {
  return await jsonApi.get<{[k: string]: GraphProperty}>(`/accounts/${accountId}/showcase/schema`, {
    headers: basicAuth(accountId),
  });
};

export async function subscribe(accountId: AccountId, subscribeRequest: SubscribeRequest) {
  const res = await jsonApi.post<SubscriptionAction>(`/accounts/${accountId}/showcase/subscribe`, subscribeRequest, {
    headers: basicAuth(accountId),
  });
  await invalidateQueries([q.vendorToolkit.trustCenterMailingList.filter()]);
  return res;
}

export async function verifySubscriptionEmail(mailSubscriptionId: MailSubscriptionId, token: string) {
  return await jsonApi.post<"ok">(
    `/mail_subscription/${mailSubscriptionId}/verify`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function sarRequest(accountId: AccountId, sarRequest: SARRequest) {
  return await jsonApi.post<"ok">(`/accounts/${accountId}/showcase/sar`, sarRequest, {
    headers: basicAuth(accountId),
  });
}
