import api from "../../../api";
import {LibrarySectionId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const librarySections = typedQuery(["vendorToolkit", "librarySections"], async () => {
  return await api.vendorToolkit.librarySections.list();
});

export const librarySection = typedQuery(
  ["vendorToolkit", "librarySection", param<LibrarySectionId>("id")],
  async id => {
    return await api.vendorToolkit.librarySections.get(id);
  },
);
