import api from "../../../api";
import {AssetId, ListAssets} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const assets = typedQuery(["vendorToolkit", "assets", param<ListAssets>("query")], async query => {
  return await api.vendorToolkit.assets.list(query);
});

export const asset = typedQuery(["vendorToolkit", "asset", param<AssetId>("assetId")], async assetId => {
  return await api.vendorToolkit.assets.get(assetId);
});
