import { JsonObject } from "./protocol"

export interface IChannel {
  subscribe(cb: (message: JsonObject) => void): () => void
  send(message: JsonObject): void
}

export class WindowMessageChannel implements IChannel {
  #sendingWindow: WindowProxy
  #sendingKey: string
  #receivingWindow: WindowProxy
  #receivingKey: string

  private constructor(
    sendingWindow: WindowProxy,
    sendingKey: string,
    receivingWindow: WindowProxy,
    receivingKey: string
  ) {
    this.#sendingWindow = sendingWindow
    this.#sendingKey = sendingKey
    this.#receivingWindow = receivingWindow
    this.#receivingKey = receivingKey
  }
  static between(
    sender: WindowProxy,
    receiver: WindowProxy,
    sendingKey: string,
    receivingKey?: string
  ): WindowMessageChannel {
    return new WindowMessageChannel(
      sender,
      sendingKey,
      receiver,
      receivingKey ?? sendingKey
    )
  }
  static within(
    window: WindowProxy,
    sendingKey: string,
    receivingKey: string
  ): WindowMessageChannel {
    return new WindowMessageChannel(window, sendingKey, window, receivingKey)
  }
  subscribe(cb: (message: JsonObject) => void): () => void {
    const onMessage = (m: MessageEvent<any>) => {
      const payload = m.data[this.#receivingKey]
      if (typeof payload !== "undefined") {
        cb(payload)
      }
    }
    this.#receivingWindow.addEventListener("message", onMessage)
    return () => {
      this.#receivingWindow.removeEventListener("message", onMessage)
    }
  }
  send(message: JsonObject): void {
    this.#sendingWindow.postMessage(
      { [this.#sendingKey]: message },
      { targetOrigin: "*" }
    )
  }
}
