import {invalidateQueries, q} from "../../state";
import {Asset, AssetMin, CreateAsset, FileId, ListAssets, AssetId} from "../../Types";
import jsonApi from "../jsonApi";

export async function list(query: ListAssets) {
  return await jsonApi.get<AssetMin[]>(`/vendor_toolkit/assets?${jsonApi.encodeQuery(query)}`);
}

export async function get(assetId: AssetId) {
  return await jsonApi.get<Asset>(`/vendor_toolkit/assets/${assetId}`);
}

export async function create(asset: CreateAsset) {
  const res = await jsonApi.post<Asset>("/vendor_toolkit/assets", asset);
  await invalidateQueries([q.vendorToolkit.assets.filter()]);
  return res;
}

export async function updateTitle(assetId: AssetId, title: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/assets/${assetId}/title`, title);
  await invalidateQueries([q.vendorToolkit.assets.filter(), q.vendorToolkit.asset.filter(assetId)]);
  return res;
}

export async function updateFile(assetId: AssetId, file_id: FileId) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/assets/${assetId}/file`, file_id);
  await invalidateQueries([q.vendorToolkit.asset.filter(assetId)]);
  return res;
}

export async function deleteAsset(assetId: AssetId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/assets/${assetId}`);
  await invalidateQueries([q.vendorToolkit.assets.filter()]);
  return res;
}
