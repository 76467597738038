import {ExternalAuthorizationId, QuestionSourceId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export const questionSourceConnectors = typedQuery(["vendorToolkit", "questionSourceConnectors"], async () => {
  return await api.vendorToolkit.questionSources.listConnectors();
});

export const questionSourceConnector = typedQuery(
  ["vendorToolkit", "questionSourceConnector", param("questionSourceConnectorId")],
  async questionSourceConnectorId => {
    return await api.vendorToolkit.questionSources.getConnector(questionSourceConnectorId);
  },
);

export const questionSources = typedQuery(["vendorToolkit", "questionSources"], async () => {
  return await api.vendorToolkit.questionSources.list();
});

export const questionSource = typedQuery(
  ["vendorToolkit", "questionSource", param<QuestionSourceId>("questionSourceId")],
  async questionSourceId => {
    return await api.vendorToolkit.questionSources.get(questionSourceId);
  },
);

export const questionSourceAccountInfo = typedQuery(
  [
    "vendorToolkit",
    "questionSourceAccountInfo",
    param("questionSourceConnectorId"),
    param<ExternalAuthorizationId>("externalAuthorizationId"),
  ],
  async (questionSourceConnectorId, externalAuthorizationId) => {
    return await api.vendorToolkit.questionSources.getAccountInfo(questionSourceConnectorId, externalAuthorizationId);
  },
);
