import api from "../../../api/index.ts";
import {param, typedQuery} from "../../typing.ts";
import {LayerType, QuestionnaireId} from "../../../Types.ts";

export const questionnaire = typedQuery(
  ["vendorToolkit", "questionnaire", param<QuestionnaireId>("questionnaireId")],
  async questionnaireId => {
    return await api.vendorToolkit.questionnaires.get(questionnaireId);
  },
);

export const questionnaires = typedQuery(
  ["vendorToolkit", "questionnaires", param<{historical: boolean}>()],
  async opts => {
    if (opts.historical) {
      return await api.vendorToolkit.questionnaires.listHistorical();
    } else {
      return await api.vendorToolkit.questionnaires.list();
    }
  },
);

export const questionnaireDocumentation = typedQuery(
  ["vendorToolkit", "questionnaireDocumentation", param<QuestionnaireId>("questionnaireId"), param<LayerType>()],
  async (questionnaireId, layerType) => {
    return await api.vendorToolkit.questionnaires.listDocumentation(questionnaireId, layerType);
  },
);
