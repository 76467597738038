import {isRouteErrorResponse} from "react-router-dom";
import {Box, Heading, Text, Flex, Button, Link, ButtonGroup} from "@chakra-ui/react";
import {RedirectError} from "./api/jsonApi";
import Loading from "./components/Loading";
import {HTTPError} from "./api";
import {ReactNode, useCallback, useEffect, useRef} from "react";
import {ErrorBoundary} from "@sentry/react";
import {router} from "./router";

type ErrorResponse = {
  status: number;
};

const httpErrors: {[status: number]: {title: string; description: string} | undefined} = {
  404: {
    title: "Page not found",
    description: "Please check the URL in the address bar and try again.",
  },
  403: {
    title: "Unauthorized",
    description: "You need to be an admin to view this page.",
  },
};

function DisplayErrorResponse({errorResponse: {status}}: {errorResponse: ErrorResponse}) {
  const {title, description} = httpErrors[status] || {title: "Unknown error"};
  return (
    <Flex minH="100vh" bg="white" pb="24" px="8" alignItems="center" justifyContent="center">
      <Box mx="auto" maxW="max">
        <Flex as="main">
          <Heading size="4xl" color="blue.500">
            {status}
          </Heading>
          <Box ml="6">
            <Box borderLeft="1px solid" borderColor="gray.200" pl="6">
              <Heading size="4xl" color="gray.800">
                {title}
              </Heading>
              {description && (
                <Text mt="2" color="gray.500">
                  {description}
                </Text>
              )}
            </Box>
            <ButtonGroup mt="10" pl="6" colorScheme="blue" shadow="sm">
              <Button as={Link} href="/" _hover={{textDecoration: "none"}}>
                Go home
              </Button>
              <Button as={Link} href="mailto:support@platformed.com" _hover={{textDecoration: "none"}}>
                Contact support
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}

function renderError({error}: {error: unknown}) {
  if (error instanceof RedirectError) {
    return <Loading m="8" />;
  } else if (error instanceof HTTPError) {
    return <DisplayErrorResponse errorResponse={error.response} />;
  } else if (isRouteErrorResponse(error)) {
    return <DisplayErrorResponse errorResponse={error} />;
  } else {
    // Shouldn't hit this case...
    return <DisplayErrorResponse errorResponse={{status: 500}} />;
  }
}

export default function ErrorRoot({children}: {children?: ReactNode}) {
  const ref = useRef<ErrorBoundary | null>(null);
  const onError = useCallback(async (error: unknown) => {
    if (error instanceof RedirectError) {
      console.info(error.toString());
      await error.response.follow();
      ref.current?.resetErrorBoundary();
    }
  }, []);

  // Reset the error when the URL changes, so that the back button works when
  // encountering a 404.
  //
  // `subscribe` is marked internal only, but it's not like they gave us any other
  // options...
  useEffect(() => router!.subscribe(() => ref.current?.resetErrorBoundary()), []);

  return (
    <ErrorBoundary ref={ref} onError={onError} fallback={renderError}>
      {children}
    </ErrorBoundary>
  );
}
