import * as batshit from "@yornaath/batshit";
import {RiskContextId, RiskId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";
import {resolver, scheduler} from "../../batch";

const risks = batshit.create({
  fetcher: api.tprm.risks.load,
  resolver: resolver("risk_id"),
  scheduler,
});

const riskContexts = batshit.create({
  fetcher: api.tprm.risks.loadContexts,
  resolver: resolver("risk_context_id"),
  scheduler,
});

export const risk = typedQuery(["tprm", "risk", param<RiskId>("riskId")], async riskId => {
  return await risks.fetch(riskId);
});

export const riskContext = typedQuery(
  ["tprm", "riskContext", param<RiskContextId>("riskContextId")],
  async riskContextId => {
    return await riskContexts.fetch(riskContextId);
  },
);
