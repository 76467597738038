import api from "../../../api";
import {DocumentId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const document = typedQuery(["vendorToolkit", "document", param<DocumentId>("documentId")], async documentId => {
  return await api.vendorToolkit.documents.get(documentId);
});

export const documentFacts = typedQuery(
  ["vendorToolkit", "documentFacts", param<DocumentId>("documentId")],
  async documentId => {
    return await api.vendorToolkit.documents.getFacts(documentId);
  },
);

export const documents = typedQuery(["vendorToolkit", "documents"], async () => {
  return await api.vendorToolkit.documents.list();
});
