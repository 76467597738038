import {
  DefaultError,
  QueryClient,
  QueryKey,
  SuspenseQueriesOptions,
  SuspenseQueriesResults,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  useSuspenseQueries,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {useLayoutEffect, useRef} from "react";

class SuspenseError extends Error {}

export function useSuspenseQueryWithDiagnostics<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseSuspenseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient,
): UseSuspenseQueryResult<TData, TError> {
  try {
    return useSuspenseQuery(options, queryClient);
  } catch (ex) {
    DIAGNOSTICS.reportPossibleSuspense(ex);
    throw ex;
  }
}

export function useSuspenseQueriesWithDiagnostics<T extends Array<any>, TCombinedResult = SuspenseQueriesResults<T>>(
  options: {
    queries: readonly [...SuspenseQueriesOptions<T>];
    combine?: (result: SuspenseQueriesResults<T>) => TCombinedResult;
  },
  queryClient?: QueryClient,
): TCombinedResult {
  try {
    return useSuspenseQueries(options, queryClient);
  } catch (ex) {
    DIAGNOSTICS.reportPossibleSuspense(ex);
    throw ex;
  }
}

export function useModalDiagnostics(isOpen: boolean) {
  const wasOpen = useRef(isOpen);
  wasOpen.current = isOpen;
  useLayoutEffect(
    () => () => {
      if (wasOpen.current) {
        DIAGNOSTICS.reportSuspenseError(new Error("Modal unmounted whilst open"));
      }
    },
    [],
  );
}

class Diagnostics {
  #activeSuspenseErrors: SuspenseError[] = [];
  debugSuspense = false;

  // This should be called from every hook which might suspend
  reportPossibleSuspense(ex: unknown) {
    if (ex instanceof Promise) {
      if (this.debugSuspense) {
        console.trace("Component suspended");
      }
      const err = new SuspenseError();
      this.#activeSuspenseErrors.push(err);
      // Remove the suspense error after 100ms
      setTimeout(() => this.#activeSuspenseErrors.splice(this.#activeSuspenseErrors.indexOf(err), 1), 100);
    }
  }

  // This can be called when an error occurs that was likely caused by a recent suspense event
  reportSuspenseError(err: Error = new Error("Invalid suspense")) {
    for (const err of this.#activeSuspenseErrors) {
      console.error(err);
    }
    this.#activeSuspenseErrors = [];
    console.error(err);
  }
}

declare global {
  interface Window {
    Diagnostics: Diagnostics;
  }
}

export const DIAGNOSTICS = new Diagnostics();

window.Diagnostics = DIAGNOSTICS;
