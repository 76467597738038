import {param, typedQuery} from "../../typing.ts";
import api from "../../../api";
import {ListNotificationsArgs, NotificationStatus, ProductType} from "../../../Types.ts";

export const notifications = typedQuery(
  [
    "notifications",
    param<ProductType>("productType"),
    param<ListNotificationsArgs>("listArgs"),
    param<NotificationStatus>("status"),
  ],
  async (productType, listArgs, status) => {
    return await api.notifications.list(productType, listArgs, status);
  },
);

export const notificationStream = typedQuery(
  ["notificationStream", param<ProductType>("productType")],
  async productType => {
    return await api.notifications.channelStream(productType);
  },
);
